import { useState } from 'react';
import { useEffect } from 'react';
import useFetch from '../../hooks/useFetch';
import useSocket from '../../hooks/useSocket';
import Button from '../Base/Button';

const Status = ({ id }) => {
	const [status, setStatus] = useState({
		linked: false,
		inputs: []
	});

	const { getSocket, socketLoading, setSocketLoading } = useSocket();
	const { postReq } = useFetch();

	useEffect(() => {
		const getLeaseWithSocket = () => {
			const socket = getSocket(
				'wss://admin-api.streamset.pro/v2/sessions/' + id + '/status',
			);
			socket.onmessage = (message) => {
				let newMessage = null
				try {
					newMessage = JSON.parse(message.data);
				} catch {}

				if (newMessage) setStatus(newMessage);

				setSocketLoading(false);
			};

			return ()=>{
				socket.onclose = ()=>{};
				socket.close();
			}
		};
		return getLeaseWithSocket();

		// eslint-disable-next-line
	}, []);


	const toggleStream = async (input) => {
		await postReq("sessions/" + id + "/toggle-input", input);
	  };

	  const toggleMute = async (input) => {
		await postReq("sessions/" + id + "/toggle-mute", input);
	  };


	if (!status.linked) return (
		<div>
			<h4>Status</h4>

			<div
				className={`encoderStatus ${socketLoading ? 'containerLoading' : ''}`}>
					no encoder linked...
				</div>
		</div>
	);

	return (
		<div style={{display: 'flex', flexDirection: "row", marginBottom: 30, justifyContent: "space-evenly"}}>
			{
				status?.inputs?.map((input, i)=>(
					<div style={{padding: 10, backgroundColor: "#111",  borderRadius: 4, minWidth: 166, display: "flex", flexDirection: "column", position:"relative"}} key={i}>
						<div style={{position: "absolute", top: 2, right: 12, cursor:"pointer", fontSize:24}} onClick={() => toggleMute(input)}>{input.muted ? "🔇" : "🔊"}</div>
						<div style={{textAlign: "center", marginBottom:6, fontWeight:600}}>{input.name}</div>
						{input.imageData ? <div style={{textAlign: "center"}}><img src={input.imageData} alt='stream preview'/></div> : null}
						<div style={{textAlign: "center"}}>{renderResolution(input.input)}</div>
						<div style={{textAlign: "center"}}>{input.status}</div>
						{input.outputTimecode ? <div style={{textAlign: "center"}}>{input.outputTimecode.split(".")[0]}</div>: null}
						<div style={{textAlign: "center", marginBottom: 10}}> <span style={{borderRadius: "50%", width: 10, height: 10, backgroundColor: renderColor(input),display: "inline-block"}}></span> {((input.outputBytes || 0)/1048576).toFixed(2)} MB</div>
						<Button title={input.enabled ? "Stop" : "Start"} func={() => toggleStream(input)} filled={!input.enabled}/>
						<div></div>
					</div>
				))
			}
		</div>
	);
};

function renderResolution(resolution) {
	if ((resolution || 0) <500) return "no input";
	return resolution + "p";

}

function renderColor(input) {
	if (input.outputCongestion || input.outputReconnecting) return "orange";
	if (input.outputDuration) return "green";
	return "red";
}

export default Status;
