import jwtDecode from 'jwt-decode';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import PageHeader from '../../componants/Base/PageHeader';
import SessionsTable from '../../componants/sessions/SessionsTable';
import Sorting from '../../componants/sessions/Sorting';
import StatusFilter from '../../componants/sessions/StatusFilter';
import useNotification from '../../hooks/useNotification';
import useSocket from '../../hooks/useSocket';
import { setQuery } from '../../Redux/Reducers/Filters';

const Sessions = () => {
	const [tableData, setData] = useState('loading');
	const user = jwtDecode(localStorage.getItem('virtualset2-admin-token'));
	const [baseData, setBaseData] = useState();
	const { mountNote } = useNotification();
	const filters = useSelector((state) => state.filters);
	const { getSocket } = useSocket();

	const dispatch = useDispatch();

	const renderStatus = (start, end) => {
		if (moment().isBefore(start)) return 'Ready';
		if (moment().isAfter(end)) return 'Finished';
		if (moment().isBetween(start, end)) return 'Active';
	};

	const filterSesions = (arr) => {
		const filtered = [];
		arr?.map((item) => {
			const itemStatus = renderStatus(item.inizio, item.fine);
			if (
				itemStatus === (filters.filters['Ready'] && 'Ready') ||
				itemStatus === (filters.filters['Finished'] && 'Finished') ||
				itemStatus === (filters.filters['Active'] && 'Active')
			) {
				filtered.push(item);
			}
			return item;
		});
		return filtered;
	};

	const search = (arr) => {
		const featured = [];
		let e = filters.searchQuery?.toLowerCase().trim();
		if (arr && !(!e || /^\s*$/.test(e))) {
			arr.map(
				(item) =>
					(item.name.toLowerCase().match(e) ||
						item?.id?.toLowerCase().match(e)) &&
					featured.push(item),
			);
			return featured;
		} else {
			return arr;
		}
	};

	useEffect(() => {
		const getSessionsWithSocket = () => {
			setData('loading');
			const sessionsSocket = getSocket(
				'wss://admin-api.streamset.pro/v2/sessions',
			);
			sessionsSocket.onmessage = (message) => {
				let body;
				try {
					body = JSON.parse(message.data);
				} catch (e) {
					mountNote('error', e.message);
				}
				if (!body) return mountNote('error', 'Empty JSON!!!');

				if (body.shape === 'virtualset.v1.sessions') {
					setBaseData(body.sessions);
				}
			};

			return ()=>{
				sessionsSocket.onclose = ()=>{};
				sessionsSocket.close();
			}
		};
		return getSessionsWithSocket();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		const oneInAll = () => {
			if (baseData) {
				const searched = search(baseData);
				const filtered = filterSesions(searched);
				const sorted = sortSessions(filtered);
				setData(sorted);
			}
		};
		oneInAll(); // eslint-disable-next-line
	}, [baseData, filters]);

	const sortSessions = (data) => {
		let sorted;
		if (Array.isArray(data)) {
			switch (filters.sortBy) {
				case 'Start/Asc':
					sorted = data.sort((a, b) => {
						return moment(a.inizio) - moment(b.inizio);
					});
					break;
				case 'Start/Desc':
					sorted = data.sort((a, b) => {
						return moment(b.inizio) - moment(a.inizio);
					});

					break;
				case 'End/Asc':
					sorted = data.sort((a, b) => {
						return moment(a.fine) - moment(b.fine);
					});
					break;
				case 'End/Desc':
					sorted = data.sort((a, b) => {
						return moment(b.fine) - moment(a.fine);
					});
					break;
				default:
					sorted = data;
			}
		}
		return sorted;
	};

	return (
		<div className='sessions'>
			<PageHeader
				placeholder={'Search with name or access code'}
				title='Sessions'
				func={(e) => dispatch(setQuery(e))}
			/>
			<div className='sessionsMainContainer'>
				<div
					className='filters'
					style={{
						pointerEvents: Array.isArray(tableData) ? 'all' : 'none',
						opacity: Array.isArray(tableData) ? 1 : 0.6,
					}}>
					<h3>Filters</h3>
					<StatusFilter filterSesions={filterSesions} />
					<Sorting sortSessions={sortSessions} />
				</div>
				<SessionsTable data={tableData} />
			</div>
			{user.shape !== 'session' && (
				<Link to='new' className='addSession'></Link>
			)}
		</div>
	);
};

export default Sessions;
