import jwtDecode from 'jwt-decode';

import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import useNotification from '../../hooks/useNotification';
import useSocket from '../../hooks/useSocket';
import CustomersTable from '../../componants/Customers/CustomersTable';
import PageHeader from '../../componants/Base/PageHeader';
import { useSelector } from 'react-redux';
import CustomerSorting from '../../componants/Customers/Sorting';
import { useDispatch } from 'react-redux';
import { setQuery } from '../../Redux/Reducers/CustomersFilters';
import StatusFilter from '../../componants/Customers/StatusFilter';

const Customers = () => {
	const [tableData, setData] = useState('loading');
	const [baseData, setBaseData] = useState();
	const { mountNote } = useNotification();
	const { getSocket } = useSocket();
	const navigate = useNavigate();
	const filters = useSelector((state) => state.CustomersFilters);
	const dispatch = useDispatch();

	const user = jwtDecode(localStorage.getItem('virtualset2-admin-token'));

	useEffect(() => {
		if (!user.superAdmin) {
			return navigate('/sessions');
		}
		const getSessionsWithSocket = () => {
			setData('loading');
			const sessionsSocket = getSocket(
				'wss://admin-api.streamset.pro/v2/customers',
			);
			sessionsSocket.onmessage = (message) => {
				let body;
				try {
					body = JSON.parse(message.data);
				} catch (e) {
					mountNote('error', e.message);
				}
				if (!body) return mountNote('error', 'Empty JSON!!!');

				if (body.shape === 'virtualset.v1.customers') {
					body.customers.map(
						(customer) =>
							(customer.recurrent = customer.recurrent
								? 'Recurrent'
								: 'Not Recurrent'),
					);
					setBaseData(body.customers);
				}
			};

			return ()=>{
				sessionsSocket.onclose = ()=>{};
				sessionsSocket.close();
			}
		};
		return getSessionsWithSocket();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		const oneInAll = () => {
			if (baseData) {
				const searched = search(baseData);
				const filtered = filterSesions(searched);
				const sorted = sortSessions(filtered);
				setData(sorted);
			}
		};
		oneInAll(); // eslint-disable-next-line
	}, [baseData, filters]);

	const sortSessions = (data) => {
		let sorted;
		if (Array.isArray(data)) {
			switch (filters.sortBy) {
				case 'Username/Asc':
					sorted = data.sort(function (a, b) {
						if (a.id < b.id) {
							return -1;
						}
						if (a.id > b.id) {
							return 1;
						}
						return 0;
					});
					break;
				case 'Username/Desc':
					sorted = data.sort(function (a, b) {
						if (a.id < b.id) {
							return 1;
						}
						if (a.id > b.id) {
							return -1;
						}
						return 0;
					});

					break;
				case 'Name/Asc':
					sorted = data.sort(function (a, b) {
						if (a.nome < b.nome) {
							return -1;
						}
						if (a.nome > b.nome) {
							return 1;
						}
						return 0;
					});
					break;
				case 'Name/Desc':
					sorted = data.sort(function (a, b) {
						if (a.nome < b.nome) {
							return 1;
						}
						if (a.nome > b.nome) {
							return -1;
						}
						return 0;
					});

					break;

				default:
					sorted = data;
			}
		}
		return sorted;
	};

	const filterSesions = (arr) => {
		const filtered = [];
		arr?.map((item) => {
			if (
				item.recurrent === (filters.filters['Recurrent'] && 'Recurrent') ||
				item.recurrent === (filters.filters['NotRecurrent'] && 'Not Recurrent')
			) {
				filtered.push(item);
			}
			return item;
		});
		return filtered;
	};

	const search = (arr) => {
		const featured = [];
		let e = filters.searchQuery?.toLowerCase().trim();
		if (arr && !(!e || /^\s*$/.test(e))) {
			arr.map(
				(item) =>
					(item?.nome?.toLowerCase().match(e) ||
						item?.id?.toLowerCase().match(e) ||
						item?.email?.toLowerCase().match(e)) &&
					featured.push(item),
			);
			return featured;
		} else {
			return arr;
		}
	};

	return (
		<div className='sessions'>
			<PageHeader
				placeholder='Search with name , username or email'
				title={'Customers'}
				func={(e) => dispatch(setQuery(e))}
			/>
			<div className='sessionsMainContainer'>
				<div
					className='filters'
					style={{
						pointerEvents: Array.isArray(tableData) ? 'all' : 'none',
						opacity: Array.isArray(tableData) ? 1 : 0.6,
					}}>
					<h3>Filters</h3>
					<StatusFilter />
					<CustomerSorting />
				</div>

				<CustomersTable data={tableData} />
			</div>
			<Link to='/customers/new' className='addSession'></Link>
		</div>
	);
};

export default Customers;
