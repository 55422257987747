import axios from 'axios';
import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import UploadIcon from '../assets/upload-cloud.svg';
import ImageList from '../componants/Files/ImageList';
import useNotification from '../hooks/useNotification';

const Upload = () => {
	const [files, setFiles] = useState(null);
	const [progress, setProgress] = useState(0);
	const { mountNote } = useNotification();
	const [images, setImages] = useState(null);

	const onDrop = useCallback(async (acceptedFiles) => {
		setFiles(
			acceptedFiles.map((file) =>
				Object.assign(file, {
					preview: URL.createObjectURL(file),
				}),
			),
		);
		const formData = new FormData();
		const imagefile = acceptedFiles;
		const url = process.env.REACT_APP_BASE_URL + 'cdn';

		formData.append('file', imagefile[0]);
		formData.append('name', imagefile[0].name.split('.')[0]);

		let message = <h2>File is too big </h2>;
		if (imagefile[0].size > 10672278) return mountNote('error', message);
		await axios
			.post(url, formData, {
				onUploadProgress: (e) => {
					let progressPer = (e.loaded / e.total) * 100;
					progressPer < 20 ? setProgress(20) : setProgress(progressPer);
				},
				headers: {
					'x-auth-token': localStorage.getItem('virtualset2-admin-token'),
					'Content-Type': 'multipart/form-data;',
				},
			})
			.then((res) => {
				setImages(res.data);

				mountNote('success', 'File uploaded succesfuly');
				setProgress(0);
				setFiles(null);
			})
			.catch((err) => {
				const message = err.response ? err.response.data : err.message;
				mountNote('error', message);
			}); // eslint-disable-next-line
	}, []);

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		maxFiles: 1,
		accept: 'image/jpeg, image/png',
	});
	return (
		<div className='uploadPage'>
			<h1>FILE UPLOAD</h1>
			<div {...getRootProps()} className='uploadZone'>
				<span
					className='progress'
					style={{ right: 100 - progress + '%' }}></span>
				<img src={UploadIcon} alt='' />
				<input {...getInputProps()} />
				<button className='upploadInput'>SELECT FILE</button>
				{isDragActive ? <p>Drop the files here ...</p> : <p>Or drag it here</p>}
				{files &&
					files.map((file, i) => (
						<div className='uploadResult' key={i}>
							<img src={file.preview} alt='' />
							<p>{file.name}</p>
						</div>
					))}
			</div>

			<ImageList added={images} />
		</div>
	);
};

export default Upload;
