import { useDispatch, useSelector } from 'react-redux';
import { setFilters } from '../../Redux/Reducers/Filters';

const StatusFilter = () => {
	const filters = useSelector((state) => state.filters.filters);
	const dispatch = useDispatch();

	return (
		<div className='status'>
			<h4>Status</h4>
			<div className='statusContainer'>
				<label>
					<input
						defaultChecked={filters['Active']}
						onChange={(e) =>
							dispatch(setFilters({ ...filters, Active: e.target.checked }))
						}
						type='checkbox'
					/>
					Active
				</label>
				<label>
					<input
						defaultChecked={filters['Ready']}
						type='checkbox'
						onChange={(e) =>
							dispatch(setFilters({ ...filters, Ready: e.target.checked }))
						}
					/>
					Ready
				</label>
				<label>
					<input
						defaultChecked={filters['Finished']}
						onChange={(e) =>
							dispatch(setFilters({ ...filters, Finished: e.target.checked }))
						}
						type='checkbox'
					/>
					Finished
				</label>
			</div>
		</div>
	);
};

export default StatusFilter;
