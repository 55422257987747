import { useEffect, useState } from 'react';
import useFetch from '../../hooks/useFetch';
import Rename from './Rename';

const ImageList = ({ added }) => {
	const { getData, deleteReq } = useFetch();
	const [images, setImages] = useState(null);
	const [showCopied, setShowCopied] = useState(false);
	const [deleting, setDeleting] = useState(false);
	const [loading, setLoading] = useState(false);
	const arr = [0, 1, 2, 3, 4, 5, 6];
	const [popUp, showPopUp] = useState(null);

	useEffect(() => {
		const getImages = async () => {
			setLoading(true);
			const data = await getData('cdn');

			setImages(data);
			setLoading(false);
		};
		getImages(); // eslint-disable-next-line
	}, []);

	const copy = (image, index) => {
		navigator.clipboard.writeText(
			'https://client-api.streamset.pro/cdn/loghi/' + image.file,
		);
		setShowCopied(index);
		setTimeout(
			() => {
				setShowCopied(null);
			},

			1500,
		);
	};

	const deletePic = async (id) => {
		setDeleting({ id: id });
		const url = 'cdn/' + id;
		const res = await deleteReq(url);
		setImages((prev) => prev.filter((image) => image._id !== res.file._id));
		setDeleting(false);
	};

	useEffect(() => {
		if (added) {
			setImages(added);
		}
	}, [added]);
	return (
		<div className='imageList'>
			{loading &&
				arr?.map((item, i) => (
					<div key={i} className='imageContainer'>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='38'
							height='38'
							stroke='#fff'
							style={{ height: '50%', width: '50%', margin: 'auto' }}
							viewBox='0 0 38 38'>
							<g
								fill='none'
								fillRule='evenodd'
								strokeWidth='2'
								transform='translate(1 1)'>
								<circle cx='18' cy='18' r='18' strokeOpacity='0.5'></circle>
								<path d='M36 18c0-9.94-8.06-18-18-18'>
									<animateTransform
										attributeName='transform'
										dur='1s'
										from='0 18 18'
										repeatCount='indefinite'
										to='360 18 18'
										type='rotate'></animateTransform>
								</path>
							</g>
						</svg>
					</div>
				))}
			{Array.isArray(images)
				? images.map((image, i) => (
						<div key={i} title={image.name} className='imageContainer'>
							<div className='img'>
								<img
									src={'https://client-api.streamset.pro/cdn/loghi/' + image.file}
									alt=''
								/>
								{showCopied === i && (
									<div className='copy'>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											viewBox='0 0 448 512'>
											<path d='M433.941 65.941l-51.882-51.882A48 48 0 0 0 348.118 0H176c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h224c26.51 0 48-21.49 48-48v-48h80c26.51 0 48-21.49 48-48V99.882a48 48 0 0 0-14.059-33.941zM266 464H54a6 6 0 0 1-6-6V150a6 6 0 0 1 6-6h74v224c0 26.51 21.49 48 48 48h96v42a6 6 0 0 1-6 6zm128-96H182a6 6 0 0 1-6-6V54a6 6 0 0 1 6-6h106v88c0 13.255 10.745 24 24 24h88v202a6 6 0 0 1-6 6zm6-256h-64V48h9.632c1.591 0 3.117.632 4.243 1.757l48.368 48.368a6 6 0 0 1 1.757 4.243V112z' />
										</svg>
										<span>Link Copied</span>
									</div>
								)}
								{deleting?.id === image._id && (
									<div className='copy'>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='38'
											height='38'
											stroke='#fff'
											style={{ height: '50%', width: '50%', margin: 'auto' }}
											viewBox='0 0 38 38'>
											<g
												fill='none'
												fillRule='evenodd'
												strokeWidth='2'
												transform='translate(1 1)'>
												<circle
													cx='18'
													cy='18'
													r='18'
													strokeOpacity='0.5'></circle>
												<path d='M36 18c0-9.94-8.06-18-18-18'>
													<animateTransform
														attributeName='transform'
														dur='1s'
														from='0 18 18'
														repeatCount='indefinite'
														to='360 18 18'
														type='rotate'></animateTransform>
												</path>
											</g>
										</svg>
									</div>
								)}
							</div>
							<div className='footer'>
								<p>{image.name} </p>
								<svg
									onClick={() =>
										showPopUp({
											id: image._id,
											value: image.name,
										})
									}
									xmlns='http://www.w3.org/2000/svg'
									viewBox='0 0 576 512'>
									<path d='M402.6 83.2l90.2 90.2c3.8 3.8 3.8 10 0 13.8L274.4 405.6l-92.8 10.3c-12.4 1.4-22.9-9.1-21.5-21.5l10.3-92.8L388.8 83.2c3.8-3.8 10-3.8 13.8 0zm162-22.9l-48.8-48.8c-15.2-15.2-39.9-15.2-55.2 0l-35.4 35.4c-3.8 3.8-3.8 10 0 13.8l90.2 90.2c3.8 3.8 10 3.8 13.8 0l35.4-35.4c15.2-15.3 15.2-40 0-55.2zM384 346.2V448H64V128h229.8c3.2 0 6.2-1.3 8.5-3.5l40-40c7.6-7.6 2.2-20.5-8.5-20.5H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V306.2c0-10.7-12.9-16-20.5-8.5l-40 40c-2.2 2.3-3.5 5.3-3.5 8.5z' />
								</svg>
								<svg
									onClick={() => copy(image, i)}
									xmlns='http://www.w3.org/2000/svg'
									viewBox='0 0 448 512'>
									<path d='M320 448v40c0 13.255-10.745 24-24 24H24c-13.255 0-24-10.745-24-24V120c0-13.255 10.745-24 24-24h72v296c0 30.879 25.121 56 56 56h168zm0-344V0H152c-13.255 0-24 10.745-24 24v368c0 13.255 10.745 24 24 24h272c13.255 0 24-10.745 24-24V128H344c-13.2 0-24-10.8-24-24zm120.971-31.029L375.029 7.029A24 24 0 0 0 358.059 0H352v96h96v-6.059a24 24 0 0 0-7.029-16.97z' />
								</svg>
								<svg
									onClick={() => deletePic(image._id)}
									xmlns='http://www.w3.org/2000/svg'
									viewBox='0 0 448 512'>
									<path d='M268 416h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12zM432 80h-82.41l-34-56.7A48 48 0 0 0 274.41 0H173.59a48 48 0 0 0-41.16 23.3L98.41 80H16A16 16 0 0 0 0 96v16a16 16 0 0 0 16 16h16v336a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128h16a16 16 0 0 0 16-16V96a16 16 0 0 0-16-16zM171.84 50.91A6 6 0 0 1 177 48h94a6 6 0 0 1 5.15 2.91L293.61 80H154.39zM368 464H80V128h288zm-212-48h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12z' />
								</svg>
							</div>
						</div>
				  ))
				: !loading && (
						<>
							<h1
								style={{ margin: 'auto', marginTop: 100, textAlign: 'center' }}>
								Nothing to show
								<br />
								{images === 'error' && 'somethong went wrong'}
							</h1>
						</>
				  )}
			{popUp && (
				<Rename
					id={popUp.id}
					value={popUp.value}
					dismiss={() => showPopUp(false)}
					updateImages={setImages}
				/>
			)}
		</div>
	);
};

export default ImageList;
