import Auth from './pages/Auth';
import './styles/main.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Upload from './pages/Upload';
import Protected from './componants/Protected';
import store from './Redux';
import { Provider } from 'react-redux';
import PaymentVerification from './pages/PaymentVerification';

//encoders
import Encoders from './pages/Encoders';
import EditCustomer from './pages/Customers/EditCustomer';
import EditEncoder from './pages/Encoders/EditEncoder';
import NewEncoder from './pages/Encoders/NewEncoder';

//sessions
import Sessions from './pages/Sessions';
import Session from './pages/Sessions/AdminSession';
import NewSession from './pages/Sessions/NewSession';

//customers
import Customers from './pages/Customers';
import NewCustomer from './pages/Customers/NewCustomer';
import CustomerSession from './pages/Sessions/CustomerSession';
//leases
import Leases from './pages/Leases';
import NewLease from './pages/Leases/NewLease';
import EditLease from './pages/Leases/EditLease';
import LinkEncoder from './pages/LinkEncoder';
import User from './pages/User';
import EventStats from './pages/EventStats';

function App() {
  return (
    <Provider store={store}>
      <Router>
        <div className="App">
          <Routes>
            <Route exact path="/auth" element={<Auth />} />
            <Route exact path="/auth/new_user" element={<NewCustomer />} />

            <Route
              exact
              path="/encoders"
              element={<Protected element={<Encoders />} />}
            />
            <Route
              exact
              path="/user"
              element={<Protected element={<User />} />}
            />
            <Route
              exact
              path="/sessions"
              element={<Protected element={<Sessions />} />}
            ></Route>
            <Route
              exact
              path="/sessions/new"
              element={<Protected element={<NewSession />} />}
            />
            <Route
              exact
              path="/files"
              element={<Protected element={<Upload />} />}
            />
            <Route
              exact
              path="/encoder/:id"
              element={<Protected element={<EditEncoder />} />}
            />
            <Route
              exact
              path="/encoders/new"
              element={<Protected element={<NewEncoder />} />}
            />
            <Route
              exact
              path="session/:id/stats"
              element={<Protected element={<EventStats />} />}
            />
            <Route
              exact
              path="/session"
              element={<Protected element={<Session />} />}
            >
              <Route
                exact
                path=":id"
                element={<Protected element={<Session />} />}
              />
            </Route>
            <Route
              path="/customer_session"
              element={<Protected element={<CustomerSession />} />}
            >
              <Route
                exact
                path=":id"
                element={<Protected element={<CustomerSession />} />}
              />
            </Route>
            <Route
              path="/leases"
              element={<Protected element={<Leases />} />}
            />
            <Route
              exact
              path="/leases/:id"
              element={<Protected element={<EditLease />} />}
            />
            <Route
              exact
              path="/leases/new"
              element={<Protected element={<NewLease />} />}
            />
            <Route
              exact
              path="/customers"
              element={<Protected element={<Customers />} />}
            />
            <Route
              exact
              path="/customers/:id"
              element={<Protected element={<EditCustomer />} />}
            />
            <Route
              exact
              path="/linkEncoder/:id"
              element={<Protected element={<LinkEncoder />} />}
            />

            <Route
              exact
              path="/customers/new"
              element={<Protected element={<NewCustomer />} />}
            />
            <Route
              exact
              path="/stripe-success"
              element={<Protected element={<PaymentVerification />} />}
            />
            <Route path="/*" element={<Navigate to="/sessions" replace />} />
          </Routes>
        </div>
      </Router>
    </Provider>
  );
}

export default App;
