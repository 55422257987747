import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../componants/Base/Button';
import Input from '../../componants/Base/Input';
import PageHeader from '../../componants/Base/PageHeader';
import useFetch from '../../hooks/useFetch';
import useNotification from '../../hooks/useNotification';

export default function NewCustomer() {
	const [customer, setCustomer] = useState({
		id: '',
		nome: '',
		email: '',
		password: '',

		phone: '',
		billing: {
			nome: '',
			vat: '',
			indirizzo: '',
			cap: '',
			city: '',
			provincia: '',
			country: '',
		},
	});
	const token = localStorage.getItem('virtualset2-admin-token');

	const [loading, setLoading] = useState(false);
	const { postReq } = useFetch();
	const { mountNote } = useNotification();
	const navigate = useNavigate();

	const submit = async () => {
		setLoading(true);
		const res = await postReq('customers', customer);
		if (res !== 'error') {
			if (token) {
				mountNote('success', 'Customer created Succesfuly');
				navigate('/customers');
			} else {
				mountNote('success', 'Account created Succesfuly , please log in ');
				navigate('/auth');
			}
		}
		setLoading(false);
	};
	return (
		<div style={{ padding: 50 }}>
			<PageHeader
				func={null}
				title={
					<>
						<span style={{ color: '#FF0000' }}>New</span> Customer
					</>
				}
			/>

			<form action=''>
				<div
					style={{
						display: 'flex',
						padding: '12px 0',
						justifyContent: 'center',
					}}>
					<div className='flexElm'>
						<Input
							placeholder='Username'
							title='Username'
							type='text'
							value={customer.id}
							onChange={(e) => setCustomer({ ...customer, id: e })}
						/>
						<Input
							placeholder='Customer Name'
							title='Name'
							type='text'
							value={customer.nome}
							onChange={(e) => setCustomer({ ...customer, nome: e })}
						/>
						<Input
							placeholder='Email'
							title='Email'
							type='email'
							value={customer.email}
							onChange={(e) => setCustomer({ ...customer, email: e })}
						/>
						<Input
							placeholder='Password'
							title='Password'
							type='password'
							value={customer.password}
							onChange={(e) => setCustomer({ ...customer, password: e })}
						/>
					</div>
					<div className='flexElm'>
						<Input
							placeholder='Phone Number'
							title='phone'
							type='tel'
							value={customer.phone}
							onChange={(e) => setCustomer({ ...customer, phone: e })}
						/>
						<Input
							placeholder='Company'
							title='Company'
							type='text'
							value={customer.billing.nome}
							onChange={(e) =>
								setCustomer({
									...customer,
									billing: {
										...customer.billing,
										nome: e,
									},
								})
							}
						/>
						<Input
							placeholder='VAT'
							title='VAT'
							type='text'
							value={customer.billing.vat}
							onChange={(e) =>
								setCustomer({
									...customer,
									billing: {
										...customer.billing,
										vat: e,
									},
								})
							}
						/>
						<Input
							placeholder='Address'
							title='Address'
							type='text'
							value={customer.billing.indirizzo}
							onChange={(e) =>
								setCustomer({
									...customer,
									billing: {
										...customer.billing,
										indirizzo: e,
									},
								})
							}
						/>
					</div>
					<div className='flexElm'>
						<Input
							placeholder='Postal Code'
							title='Postal Code'
							type='text'
							value={customer.billing.cap}
							onChange={(e) =>
								setCustomer({
									...customer,
									billing: {
										...customer.billing,
										cap: e,
									},
								})
							}
						/>
						<Input
							placeholder='city'
							title='city'
							type='text'
							value={customer.billing.city}
							onChange={(e) =>
								setCustomer({
									...customer,
									billing: {
										...customer.billing,
										city: e,
									},
								})
							}
						/>
						<Input
							placeholder='State'
							title='State'
							type='text'
							value={customer.billing.provincia}
							onChange={(e) =>
								setCustomer({
									...customer,
									billing: {
										...customer.billing,
										provincia: e,
									},
								})
							}
						/>
						<Input
							placeholder='Country'
							title='Country'
							type='text'
							value={customer.billing.country}
							onChange={(e) =>
								setCustomer({
									...customer,
									billing: {
										...customer.billing,
										country: e,
									},
								})
							}
						/>
					</div>
				</div>
			</form>
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<Button
					filled
					disabled={loading}
					loading={loading}
					title='Create'
					func={submit}
				/>
			</div>
		</div>
	);
}
