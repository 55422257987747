import jwtDecode from 'jwt-decode';
import { useEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

const Header = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const user = jwtDecode(localStorage.getItem('virtualset2-admin-token'));
	const [opened, setOpend] = useState(false);

	const logout = () => {
		localStorage.removeItem('virtualset2-admin-token');
		navigate('/auth');
	};

	useEffect(() => {
		setOpend(false);
	}, [location]);
	return (
		<nav className='nav'>
			<img
				src='https://client-api.streamset.pro/cdn/loghi/1628700010437-streamset-bianco.svg'
				alt='logo'
			/>
			<button
				onClick={() => setOpend(!opened)}
				className={`menuToggle ${opened && 'close'}`}>
				<span></span>
			</button>
			<div className={`links ${opened && 'opened'}`}>
				{user.superAdmin && (
					<>
						<NavLink
							className={({ isActive }) => (isActive ? 'activeLink' : '')}
							to='/encoders'>
							ENCODERS
						</NavLink>
						<NavLink
							className={({ isActive }) => (isActive ? 'activeLink' : '')}
							to='/leases'>
							LEASES
						</NavLink>
						<NavLink
							className={({ isActive }) => (isActive ? 'activeLink' : '')}
							to='/customers'>
							CUSTOMERS
						</NavLink>
					</>
				)}

				<NavLink
					className={({ isActive }) => (isActive ? 'activeLink' : '')}
					to='/sessions'>
					SESSIONS
				</NavLink>

				<NavLink
					className={({ isActive }) => (isActive ? 'activeLink' : '')}
					to='/files'>
					FILES
				</NavLink>
				{!user.superAdmin && user.shape !== 'session' && (
					<NavLink
						className={({ isActive }) => (isActive ? 'activeLink' : '')}
						style={{ display: 'flex', alignItems: 'center' }}
						to='/user'>
						<svg
							height={25}
							fill='white'
							style={{ margin: '0 10px' }}
							xmlns='http://www.w3.org/2000/svg'
							viewBox='0 0 448 512'>
							<path d='M313.6 304c-28.7 0-42.5 16-89.6 16-47.1 0-60.8-16-89.6-16C60.2 304 0 364.2 0 438.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-25.6c0-74.2-60.2-134.4-134.4-134.4zM400 464H48v-25.6c0-47.6 38.8-86.4 86.4-86.4 14.6 0 38.3 16 89.6 16 51.7 0 74.9-16 89.6-16 47.6 0 86.4 38.8 86.4 86.4V464zM224 288c79.5 0 144-64.5 144-144S303.5 0 224 0 80 64.5 80 144s64.5 144 144 144zm0-240c52.9 0 96 43.1 96 96s-43.1 96-96 96-96-43.1-96-96 43.1-96 96-96z' />
						</svg>
						USER
					</NavLink>
				)}
				<button onClick={logout}>
					<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
						<path d='M497 273L329 441c-15 15-41 4.5-41-17v-96H152c-13.3 0-24-10.7-24-24v-96c0-13.3 10.7-24 24-24h136V88c0-21.4 25.9-32 41-17l168 168c9.3 9.4 9.3 24.6 0 34zM192 436v-40c0-6.6-5.4-12-12-12H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h84c6.6 0 12-5.4 12-12V76c0-6.6-5.4-12-12-12H96c-53 0-96 43-96 96v192c0 53 43 96 96 96h84c6.6 0 12-5.4 12-12z' />
					</svg>
					LOGOUT
				</button>
			</div>
		</nav>
	);
};

export default Header;
