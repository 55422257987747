import React from 'react';
import reactDom from 'react-dom';

const Notification = ({ type, message, linkName, linkPath }) => {
	return (
		<div className='noteContainer'>
			<div className='note'>
				<div
					className='header'
					style={{
						background:
							type === 'success'
								? '#3ca783'
								: type === 'error'
								? '#c94646'
								: '#626262',
					}}>
					<h3>{type}</h3>
					<button
						onClick={() => {
							reactDom.unmountComponentAtNode(document.getElementById('note'));
						}}>
						<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
							<path d='M464 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm0 394c0 3.3-2.7 6-6 6H54c-3.3 0-6-2.7-6-6V86c0-3.3 2.7-6 6-6h404c3.3 0 6 2.7 6 6v340zM356.5 194.6L295.1 256l61.4 61.4c4.6 4.6 4.6 12.1 0 16.8l-22.3 22.3c-4.6 4.6-12.1 4.6-16.8 0L256 295.1l-61.4 61.4c-4.6 4.6-12.1 4.6-16.8 0l-22.3-22.3c-4.6-4.6-4.6-12.1 0-16.8l61.4-61.4-61.4-61.4c-4.6-4.6-4.6-12.1 0-16.8l22.3-22.3c4.6-4.6 12.1-4.6 16.8 0l61.4 61.4 61.4-61.4c4.6-4.6 12.1-4.6 16.8 0l22.3 22.3c4.7 4.6 4.7 12.1 0 16.8z' />
						</svg>
					</button>
				</div>
				<div className='body'>
					{message}
					{linkPath && <a href={linkPath}> {linkName}</a>}
				</div>
			</div>
		</div>
	);
};

export default Notification;
