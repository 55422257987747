import { createSlice } from '@reduxjs/toolkit';

export const EncodersSlice = createSlice({
	name: 'encodersFilters',
	initialState: {
		sortBy: 'Code/Asc',
		filters: {
			New: true,
			Offline: true,
			Online: true,
		},
		searchQuery: '',
	},
	reducers: {
		setSortBy: (state, action) => {
			state.sortBy = action.payload;
		},
		setFilters: (state, action) => {
			state.filters = action.payload;
		},
		setQuery: (state, action) => {
			state.searchQuery = action.payload;
		},
	},
});

export const { setSortBy, setFilters, setQuery } = EncodersSlice.actions;

export default EncodersSlice.reducer;
