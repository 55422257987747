import { createSlice } from '@reduxjs/toolkit';

export const CustomersSlice = createSlice({
	name: 'customersFilters',
	initialState: {
		sortBy: 'Username/Asc',
		filters: {
			Recurrent: true,
			NotRecurrent: true,
		},
		searchQuery: '',
	},
	reducers: {
		setSortBy: (state, action) => {
			state.sortBy = action.payload;
		},
		setFilters: (state, action) => {
			state.filters = action.payload;
		},
		setQuery: (state, action) => {
			state.searchQuery = action.payload;
		},
	},
});

export const { setSortBy, setFilters, setQuery } = CustomersSlice.actions;

export default CustomersSlice.reducer;
