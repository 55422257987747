import { useState } from 'react';
import { useEffect } from 'react';
import Button from '../componants/Base/Button';
import Input from '../componants/Base/Input';
import useNotification from '../hooks/useNotification';
import useFetch from '../hooks/useFetch';
import useSocket from '../hooks/useSocket';
import jwtDecode from 'jwt-decode';

const User = () => {
	// hooks and utils
	const user = jwtDecode(localStorage.getItem('virtualset2-admin-token'));
	const id = user._id;
	const { getSocket, setSocketLoading, socketLoading } = useSocket();
	const { mountNote } = useNotification();
	const { postReq } = useFetch();

	//states
	const [customer, setCustomer] = useState({
		_id: '',
		nome: '',
		email: '',
		phone: '',
		logo: '',
		password: '',
		secPassword: '',
	});
	const [baseCustomer, setBaseCustomer] = useState({
		nome: '',
		email: '',
		phone: '',
		logo: '',
		password: '',
		secPassword: '',
	});
	const [loading, setLoading] = useState(false);
	const [upgradeLoading, setUpgradeLoading] = useState(false);

	//get socket (out of useEffect so i can use it in reset)
	const getEncoderSocket = () => {
		const socket = getSocket(
			'wss://admin-api.streamset.pro/v2/customers/' + id,
		);
		socket.onmessage = (message) => {
			let body;
			try {
				body = JSON.parse(message.data);
			} catch (e) {
				mountNote('error', e.message);
			}
			if (!body) return mountNote('error', 'Empty JSON!!!');

			if (body.shape === 'virtualset.v1.customer') {
				setCustomer({
					_id: body.customer._id,
					nome: body.customer.nome,
					email: body.customer.email,
					phone: body.customer.phone,
					logo: body.customer.logo,
					password: '',
					secPassword: '',
				});
				setBaseCustomer({
					nome: body.customer.nome,
					email: body.customer.email,
					phone: body.customer.phone,
					logo: body.customer.logo,
					password: '',
					secPassword: '',
				});
			}
			setSocketLoading(false);
		};

		return ()=>{
			socket.onclose = ()=>{};
			socket.close();
		}
	};

	//submit changes

	const submit = async () => {
		if (customer.password !== customer.secPassword) {
			return mountNote('error', 'Passwords must be identical');
		}
		let dataload = { ...customer };
		setLoading(true);
		Object.keys(dataload).map((customerKey) =>
			dataload[customerKey] === baseCustomer[customerKey]
				? delete dataload[customerKey]
				: customer,
		);

		delete dataload.secPassword;

		const res = await postReq('customers/' + id, dataload);
		res !== 'error' && mountNote('success', 'User edited succesfuly');
		setLoading(false);
	};

	//upgrade user
	const upgrade = async () => {
		setUpgradeLoading(true);
		const res = await postReq('customers/' + customer._id + '/upgrade', {
			origin: window.location.origin,
		});
		if (res !== 'error') {
			if (res.redirect) {
				window.location.href = res.redirect;
			}
		}

		setUpgradeLoading(false);
	};

	useEffect(() => {
		return getEncoderSocket(); // eslint-disable-next-line
	}, []);

	return (
		<div
			className={socketLoading ? 'containerLoading' : ''}
			style={{ padding: 50 }}>
			<h1>
				<span style={{ color: '#FF0000' }}> EDIT</span> ACCOUNT
			</h1>
			<form
				style={{
					display: 'flex',
					padding: '12px 0',
					justifyContent: 'space-between',
				}}>
				<div style={{ width: '30%' }}>
					<Input
						filled={customer.nome !== baseCustomer.nome}
						containerStyle={style.inpCont}
						title='nome'
						type='text'
						value={customer.nome}
						onChange={(e) => setCustomer({ ...customer, nome: e })}
					/>
					<Input
						filled={customer.email !== baseCustomer.email}
						containerStyle={style.inpCont}
						title='Email'
						type='email'
						value={customer.email}
						onChange={(e) => setCustomer({ ...customer, email: e })}
					/>
				</div>
				<div style={{ width: '30%' }}>
					<Input
						filled={customer.phone !== baseCustomer.phone}
						containerStyle={style.inpCont}
						title='Phone'
						type='tel'
						value={customer.phone}
						onChange={(e) => setCustomer({ ...customer, phone: e })}
					/>
					<Input
						filled={customer.password !== baseCustomer.password}
						containerStyle={style.inpCont}
						title='Change Password'
						type='password'
						value={customer.password}
						onChange={(e) => setCustomer({ ...customer, password: e })}
					/>
				</div>
				<div style={{ width: '30%' }}>
					<Input
						filled={customer.logo !== baseCustomer.logo}
						containerStyle={style.inpCont}
						title='Logo'
						type='URL'
						value={customer.logo}
						onChange={(e) => setCustomer({ ...customer, logo: e })}
					/>
					<Input
						filled={customer.secPassword !== baseCustomer.secPassword}
						containerStyle={style.inpCont}
						title='Repeat Password'
						type='password'
						value={customer.secPassword}
						onChange={(e) => setCustomer({ ...customer, secPassword: e })}
					/>
				</div>
			</form>

			<div className='buttons'>
				<Button
					disabled={loading}
					title='SAVE'
					loading={loading}
					filled
					func={() => submit()}
				/>
				{!user.recurrent && (
					<Button
						title='BECOME PREMIUM'
						loading={upgradeLoading}
						func={upgrade}
					/>
				)}
			</div>
		</div>
	);
};
const style = {
	inpCont: {
		margin: 0,
		marginBottom: 15,
	},
	buttons: {
		margin: 'auto',
		width: 'fit-content',
	},
};
export default User;
