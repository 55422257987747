import { useDispatch, useSelector } from 'react-redux';
import { setFilters } from '../../Redux/Reducers/CustomersFilters';

const StatusFilter = () => {
	const filters = useSelector((state) => state.CustomersFilters.filters);
	const dispatch = useDispatch();

	return (
		<div className='status'>
			<h4>Position</h4>
			<div className='statusContainer'>
				<label>
					<input
						defaultChecked={filters['Recurrent']}
						type='checkbox'
						onChange={(e) =>
							dispatch(setFilters({ ...filters, Recurrent: e.target.checked }))
						}
					/>
					Recurrent
				</label>
				<label>
					<input
						defaultChecked={filters['NotRecurrent']}
						type='checkbox'
						onChange={(e) =>
							dispatch(
								setFilters({ ...filters, NotRecurrent: e.target.checked }),
							)
						}
					/>
					Not Recurrent
				</label>
			</div>
		</div>
	);
};

export default StatusFilter;
