import { useState } from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Button from '../../componants/Base/Button';
import Input from '../../componants/Base/Input';
import useFetch from '../../hooks/useFetch';
import useNotification from '../../hooks/useNotification';
import useSocket from '../../hooks/useSocket';

const EditCustomer = () => {
	// hooks and utils
	const { id } = useParams();
	const { getSocket, setSocketLoading, socketLoading } = useSocket();
	const { mountNote } = useNotification();
	const { postReq } = useFetch();

	//states
	const [customer, setCustomer] = useState({
		id: '',
		nome: '',
		email: '',
		password: '',
		phone: '',
		logo: '',
		billing: {
			nome: '',
			vat: '',
			indirizzo: '',
			cap: '',
			city: '',
			provincia: '',
			country: '',
		},
		pricing: {
			net: 290,
			netRecurrent: 2900,
			vat: 63.8,
			vatRecurrent: 638,
			total: 353.8,
			totalRecurrent: 3538,
		},
	});
	const [loading, setLoading] = useState(false);

	//get socket (out of useEffect so i can use it in reset)
	const getcustomerSocket = () => {
		const socket = getSocket(
			'wss://admin-api.streamset.pro/v2/customers/' + id,
		);
		socket.onmessage = (message) => {
			let body;
			try {
				body = JSON.parse(message.data);
			} catch (e) {
				mountNote('error', e.message);
			}
			if (!body) return mountNote('error', 'Empty JSON!!!');

			if (body.shape === 'virtualset.v1.customer') {
				setCustomer({ ...customer, ...body.customer });
			}
			setSocketLoading(false);
		};

		return ()=>{
			socket.onclose = ()=>{};
			socket.close();
		}
	};

	//submit changes

	const submit = async () => {
		setLoading(true);
		let dataload = { ...customer };
		delete dataload._id;

		const res = await postReq('customers/' + id, dataload);
		res !== 'error' && mountNote('success', 'Customer edited succesfuly');
		setLoading(false);
	};

	useEffect(() => {
		return getcustomerSocket(); // eslint-disable-next-line
	}, []);

	useEffect(() => {
		setCustomer({
			...customer,
			pricing: {
				...customer.pricing,
				total: Number(customer.pricing.net) + Number(customer.pricing.vat),
			},
		}); // eslint-disable-next-line
	}, [customer.pricing.net, customer.pricing.vat]);
	useEffect(() => {
		setCustomer({
			...customer,
			pricing: {
				...customer.pricing,
				totalRecurrent:
					Number(customer.pricing.netRecurrent) +
					Number(customer.pricing.vatRecurrent),
			},
		}); // eslint-disable-next-line
	}, [customer.pricing.netRecurrent, customer.pricing.vatRecurrent]);

	return (
		<div
			style={{ padding: 50 }}
			className={socketLoading ? 'containerLoading' : ''}>
			<h1>
				CUSTOMER
				<span style={{ color: '#FF0000' }}> {customer.nome}</span>
			</h1>
			<form
				style={{
					display: 'flex',
					padding: '12px 0',
					justifyContent: 'space-between',
					flexWrap: 'wrap',
				}}>
				<div className='flexElm'>
					<Input
						placeholder='Logo'
						title='logo'
						type='text'
						value={customer.logo}
						onChange={(e) => setCustomer({ ...customer, logo: e })}
					/>
					<Input
						placeholder='Customer Name'
						title='Name'
						type='text'
						value={customer.nome}
						onChange={(e) => setCustomer({ ...customer, nome: e })}
					/>
					<Input
						placeholder='Email'
						title='Email'
						type='email'
						value={customer.email}
						onChange={(e) => setCustomer({ ...customer, email: e })}
					/>
					<Input
						placeholder='Input a new password to change it'
						title='Change Password'
						type='password'
						value={customer.password}
						onChange={(e) => setCustomer({ ...customer, password: e })}
					/>
				</div>
				<div className='flexElm'>
					<Input
						placeholder='Phone Number'
						title='phone'
						type='tel'
						value={customer.phone}
						onChange={(e) => setCustomer({ ...customer, phone: e })}
					/>
					<Input
						placeholder='Company'
						title='Company'
						type='text'
						value={customer.billing.nome}
						onChange={(e) =>
							setCustomer({
								...customer,
								billing: {
									...customer.billing,
									nome: e,
								},
							})
						}
					/>
					<Input
						placeholder='VAT'
						title='VAT'
						type='text'
						value={customer.billing.vat}
						onChange={(e) =>
							setCustomer({
								...customer,
								billing: {
									...customer.billing,
									vat: e,
								},
							})
						}
					/>
					<Input
						placeholder='Address'
						title='Address'
						type='text'
						value={customer.billing.indirizzo}
						onChange={(e) =>
							setCustomer({
								...customer,
								billing: {
									...customer.billing,
									indirizzo: e,
								},
							})
						}
					/>
				</div>
				<div className='flexElm'>
					<Input
						placeholder='Postal Code'
						title='Postal Code'
						type='text'
						value={customer.billing.cap}
						onChange={(e) =>
							setCustomer({
								...customer,
								billing: {
									...customer.billing,
									cap: e,
								},
							})
						}
					/>
					<Input
						placeholder='city'
						title='city'
						type='text'
						value={customer.billing.city}
						onChange={(e) =>
							setCustomer({
								...customer,
								billing: {
									...customer.billing,
									city: e,
								},
							})
						}
					/>
					<Input
						placeholder='State'
						title='State'
						type='text'
						value={customer.billing.provincia}
						onChange={(e) =>
							setCustomer({
								...customer,
								billing: {
									...customer.billing,
									provincia: e,
								},
							})
						}
					/>
					<Input
						placeholder='Country'
						title='Country'
						type='text'
						value={customer.billing.country}
						onChange={(e) =>
							setCustomer({
								...customer,
								billing: {
									...customer.billing,
									country: e,
								},
							})
						}
					/>
				</div>
				<h3 className='dividerHeader'>DAILY PRICE</h3>
				<div className='flexElm'>
					<Input
						placeholder='Price'
						title='Price'
						type='number'
						value={customer.pricing.net}
						onChange={(e) =>
							setCustomer({
								...customer,
								pricing: {
									...customer.pricing,
									net: e,
								},
							})
						}
					/>
				</div>
				<div className='flexElm'>
					<Input
						placeholder='Taxes'
						title='Taxes'
						type='number'
						value={customer.pricing.vat}
						onChange={(e) => {
							setCustomer({
								...customer,
								pricing: {
									...customer.pricing,
									vat: e,
								},
							});
						}}
					/>
				</div>
				<div className='flexElm'>
					<Input
						disabled
						placeholder='Total'
						title='Total'
						type='text'
						value={customer.pricing.total}
					/>
				</div>

				<h3 className='dividerHeader'>RECURRENT PRICE</h3>
				<div className='flexElm'>
					<Input
						placeholder='Price'
						title='Price'
						type='number'
						value={customer.pricing.netRecurrent}
						onChange={(e) =>
							setCustomer({
								...customer,
								pricing: {
									...customer.pricing,
									netRecurrent: e,
								},
							})
						}
					/>
				</div>
				<div className='flexElm'>
					<Input
						placeholder='Taxes'
						title='Taxes'
						type='number'
						value={customer.pricing.vatRecurrent}
						onChange={(e) => {
							setCustomer({
								...customer,
								pricing: {
									...customer.pricing,
									vatRecurrent: e,
								},
							});
						}}
					/>
				</div>
				<div className='flexElm'>
					<Input
						disabled
						placeholder='Total'
						title='Total'
						type='text'
						value={customer.pricing.totalRecurrent}
					/>
				</div>
			</form>

			<div className='buttons'>
				<Button
					disabled={loading}
					title='RESET'
					func={() => getcustomerSocket()}
				/>
				<Button
					disabled={loading}
					title='SAVE'
					loading={loading}
					filled
					func={() => submit()}
				/>
			</div>
		</div>
	);
};

export default EditCustomer;
