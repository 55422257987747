const Select = ({
	title,
	containerStyle,
	value,
	children,
	select,
	disabled,
	loading,
}) => {
	return (
		<div className='customInput' style={containerStyle}>
			<p>{title}</p>
			<div className='customSelect'>
				<select
					disabled={disabled}
					value={value}
					onChange={(e) => select(e.target.value)}>
					{children}
				</select>
				{loading && (
					<svg
						className='selectLoader'
						xmlns='http://www.w3.org/2000/svg'
						width='15'
						height='15'
						stroke='#fff'
						style={{ marginLeft: 10 }}
						viewBox='0 0 38 38'>
						<g
							fill='none'
							fillRule='evenodd'
							strokeWidth='4'
							transform='translate(1 1)'>
							<circle cx='18' cy='18' r='18' strokeOpacity='0.5'></circle>
							<path d='M36 18c0-9.94-8.06-18-18-18'>
								<animateTransform
									attributeName='transform'
									dur='1s'
									from='0 18 18'
									repeatCount='indefinite'
									to='360 18 18'
									type='rotate'></animateTransform>
							</path>
						</g>
					</svg>
				)}
			</div>
		</div>
	);
};

export default Select;
