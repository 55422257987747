import { useState } from 'react';
import useNotification from './useNotification';

let prevcta = {};

const useSocket = () => {
	const { mountNote } = useNotification();
	const [socketLoading, setSocketLoading] = useState(false);
	var count = 0;
	const getSocket = (url) => {
		setSocketLoading(true);
		const token = localStorage.getItem('virtualset2-admin-token');
		const socket = new WebSocket(url, token);
		socket.onopen = () => {
			count =0;
			setSocketLoading(false);
			setTimeout(()=>{setSocketLoading(false);},100);
		};

		if (prevcta[url]) socket.onmessage = prevcta[url];

		socket.onclose = (e) => {
			const message = 'Socket is closed. Please try reloading the page.';
			if (!navigator.onLine) {
				setSocketLoading(false);
				mountNote(
					'error',
					'You are offline. Please connect to the Internet and try again.',
				);
				return;
			}
			if (e.code === 1008 || e.code === 1007) {
				setSocketLoading(false);
				return mountNote('error', e.reason);
			} else if (e.code === 1006) {
				if (count < 20) {
					count += 1;

					prevcta[url] = socket.onmessage;
					setTimeout(() => {
						getSocket(url);
					}, 1000);
				} else {
					setSocketLoading(false);
					mountNote('error', message);
				}
			}
		};
		socket.onerror = (e) => {
			setSocketLoading(false);
			const message = 'Socket is closed. Please try reloading the page.';
			if (e.code === 1008 || e.code === 1007) {
				return mountNote('error', e.reason);
			} else if (e.code === 1006) {
				if (count < 20) {
					count += 1;
					setTimeout(() => {
						getSocket(url);
					}, 1000);
				} else {
					mountNote('error', message);
				}
			} else {
				mountNote('error', message);
			}

			return;
		};

		return socket;
	};

	return { getSocket, socketLoading, setSocketLoading };
};

export default useSocket;
