import jwtDecode from 'jwt-decode';
import moment from 'moment';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../componants/Base/Button';
import Input from '../../componants/Base/Input';
import Switch from '../../componants/Base/Switch';
import useFetch from '../../hooks/useFetch';
import useNotification from '../../hooks/useNotification';

const NewSession = () => {
	const user = jwtDecode(localStorage.getItem('virtualset2-admin-token'));
	const { postReq } = useFetch();
	const { mountNote } = useNotification();
	const navigate = useNavigate();

	const [values, setValues] = useState({
		name: '',
		inizio: moment().format('YYYY-MM-DDThh:mm'),
		days: 1,
		hasAudio: false,
		hasChat: true,
		origin: window.location.origin,
	});
	const [loading, setLoading] = useState(false);

	const createSession = async () => {
		setLoading(true);
		const res = await postReq('sessions', values);

		checkRes(res);
		setLoading(false);
	};

	const checkRes = (res) => {
		if (res !== 'error') {
			if (res.redirect) {
				window.location.href = res.redirect;
			} else {
				mountNote('success', 'Session created successfully');
				navigate('/sessions');
			}
		}
	};

	return (
		<div style={{ padding: 50 }}>
			<h1>
				<span style={{ color: '#FF0000' }}>New</span> Session
			</h1>
			<form
				action=''
				style={{
					display: 'flex',
					padding: '12px 0',
					justifyContent: 'center',
				}}>
				<div className='flexElm'>
					<Input
						placeholder='Session Name'
						title='Name'
						type='text'
						value={values.name}
						onChange={(e) => setValues({ ...values, name: e })}
					/>

					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<Input
							containerStyle={{ width: '70%' }}
							title='Start'
							value={values.inizio}
							type='datetime-local'
							onChange={(e) => setValues({ ...values, inizio: e })}
						/>
						<Input
							containerStyle={{ width: '25%' }}
							title='Days'
							value={values.days}
							type='number'
							onChange={(e) => setValues({ ...values, days: e })}
						/>
					</div>
					{user.shape === 'superadmin' && (
						<Input
							title='Customer'
							placeholder='Customer ID'
							type='text'
							value={values.customerId || ''}
							onChange={(e) => setValues({ ...values, customerId: e })}
						/>
					)}
				</div>
				<div className='flexElm' style={{ marginTop: 25 }}>
					<Switch
						title='Text Chat'
						checked={values.hasChat}
						onChange={(e) => setValues({ ...values, hasChat: e })}
					/>
					<Switch
						title='Audio-Video Chat'
						checked={values.hasAudio}
						onChange={(e) => setValues({ ...values, hasAudio: e })}
					/>
				</div>
			</form>
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<Button filled loading={loading} title='Create' func={createSession} />
			</div>
		</div>
	);
};

export default NewSession;
