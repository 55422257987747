import jwtDecode from "jwt-decode";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Reload from "./Reload";

const SessionsTable = ({ data }) => {
  const navigate = useNavigate();
  const user = jwtDecode(localStorage.getItem("virtualset2-admin-token"));

  return (
    <div
      style={{
        width: "100%",
      }}
    >
      {data === "error" ? (
        <h1
          className="Finished"
          style={{
            color: "#5c0a0a",
            backgroundColor: "#fed5d5",
            height: "350px",
            lineHeight: "350px",
            textAlign: "center",
          }}
        >
          Something went wrong , please try again later!
        </h1>
      ) : (
        <table className="sessionsTable" style={{ position: "relative" }}>
          <thead>
            <tr>
              <th>
                <span>Num</span>
              </th>
              <th>
                <span>Name</span>
              </th>
              <th>
                <span>Access Code</span>
              </th>
              <th>
                <span>Status</span>
              </th>
              <th>
                <span>Start</span>
              </th>
              <th>
                <span>End</span>
              </th>
              <th>
                <span></span>
              </th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(data) &&
              data?.map((row, i) => (
                <tr key={i}>
                  <td>
                    <span>{row?.players}</span>
                  </td>
                  <td>
                    <span> {row.name}</span>
                  </td>
                  <td>
                    <span>{row.id}</span>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <span className={`tag ${row.status}`}>{row.status}</span>
                  </td>
                  <td>
                    <span>{moment(row.inizio).format("DD/MM/YYYY")}</span>
                  </td>
                  <td>
                    <span>{moment(row.fine).format("DD/MM/YYYY")}</span>
                  </td>
                  <td>
                    <span style={{ display: "flex" }}>
                      <svg
                        onClick={() => {
                          navigate(
                            user.superAdmin
                              ? `/session/${row._id}`
                              : `/customer_session/${row._id}`,
                            {
                              state: row,
                            }
                          );
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 576 512"
                      >
                        <path d="M402.3 344.9l32-32c5-5 13.7-1.5 13.7 5.7V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h273.5c7.1 0 10.7 8.6 5.7 13.7l-32 32c-1.5 1.5-3.5 2.3-5.7 2.3H48v352h352V350.5c0-2.1.8-4.1 2.3-5.6zm156.6-201.8L296.3 405.7l-90.4 10c-26.2 2.9-48.5-19.2-45.6-45.6l10-90.4L432.9 17.1c22.9-22.9 59.9-22.9 82.7 0l43.2 43.2c22.9 22.9 22.9 60 .1 82.8zM460.1 174L402 115.9 216.2 301.8l-7.3 65.3 65.3-7.3L460.1 174zm64.8-79.7l-43.2-43.2c-4.1-4.1-10.8-4.1-14.8 0L436 82l58.1 58.1 30.9-30.9c4-4.2 4-10.8-.1-14.9z" />
                      </svg>
                      <svg
                        onClick={() => {
                          navigate(
                            user.superAdmin
                              ? `/session/${row._id}/stats`
                              : `/customer_session/${row._id}`,
                            {
                              state: row,
                            }
                          );
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 640 512"
                      >
                        <path d="M144 0a80 80 0 1 1 0 160A80 80 0 1 1 144 0zM512 0a80 80 0 1 1 0 160A80 80 0 1 1 512 0zM0 298.7C0 239.8 47.8 192 106.7 192h42.7c15.9 0 31 3.5 44.6 9.7c-1.3 7.2-1.9 14.7-1.9 22.3c0 38.2 16.8 72.5 43.3 96c-.2 0-.4 0-.7 0H21.3C9.6 320 0 310.4 0 298.7zM405.3 320c-.2 0-.4 0-.7 0c26.6-23.5 43.3-57.8 43.3-96c0-7.6-.7-15-1.9-22.3c13.6-6.3 28.7-9.7 44.6-9.7h42.7C592.2 192 640 239.8 640 298.7c0 11.8-9.6 21.3-21.3 21.3H405.3zM224 224a96 96 0 1 1 192 0 96 96 0 1 1 -192 0zM128 485.3C128 411.7 187.7 352 261.3 352H378.7C452.3 352 512 411.7 512 485.3c0 14.7-11.9 26.7-26.7 26.7H154.7c-14.7 0-26.7-11.9-26.7-26.7z" />
                      </svg>
                      <a
                        href={`https://admin-api.streamset.pro/v2/sessions/${row._id}/streamset.pdf`}
                        target="_blank"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 26"
                          width="20"
                          height="26"
                        >
                          <path
                            d="M11.9014 7.1499V0.509277H2.13574C1.48633 0.509277 0.963867 1.03174 0.963867 1.68115V24.3374C0.963867 24.9868 1.48633 25.5093 2.13574 25.5093H18.542C19.1914 25.5093 19.7139 24.9868 19.7139 24.3374V8.32178H13.0732C12.4287 8.32178 11.9014 7.79443 11.9014 7.1499ZM15.6343 17.4702L10.9263 22.1431C10.6016 22.4658 10.0771 22.4658 9.75244 22.1431L5.04443 17.4702C4.54883 16.9785 4.89648 16.1343 5.59375 16.1343H8.77637V12.228C8.77637 11.7964 9.12598 11.4468 9.55762 11.4468H11.1201C11.5518 11.4468 11.9014 11.7964 11.9014 12.228V16.1343H15.084C15.7812 16.1343 16.1289 16.9785 15.6343 17.4702ZM19.3721 5.63623L14.5918 0.851074C14.3721 0.631348 14.0742 0.509277 13.7617 0.509277H13.4639V6.75928H19.7139V6.46143C19.7139 6.15381 19.5918 5.85596 19.3721 5.63623Z"
                            fill="white"
                          />
                        </svg>
                      </a>

                      <Reload id={row._id} />
                    </span>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      )}
      {data === "loading" && (
        <div
          style={{
            padding: "50px 0",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "#dcdcdc1c",
          }}
        >
          <h3>Loading</h3>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="38"
            height="38"
            stroke="#fff"
            style={{ height: "20px", cursor: "auto" }}
            viewBox="0 0 38 38"
          >
            <g
              fill="none"
              fillRule="evenodd"
              strokeWidth="2"
              transform="translate(1 1)"
            >
              <circle cx="18" cy="18" r="18" strokeOpacity="0.5"></circle>
              <path d="M36 18c0-9.94-8.06-18-18-18">
                <animateTransform
                  attributeName="transform"
                  dur="1s"
                  from="0 18 18"
                  repeatCount="indefinite"
                  to="360 18 18"
                  type="rotate"
                ></animateTransform>
              </path>
            </g>
          </svg>
        </div>
      )}
    </div>
  );
};

export default SessionsTable;
