import { useState } from 'react';
import { useEffect } from 'react';
import useNotification from '../../hooks/useNotification';
import useSocket from '../../hooks/useSocket';

const Logs = ({ id }) => {
	const [logs, setLogs] = useState('');

	const { getSocket, socketLoading, setSocketLoading } = useSocket();
	const { mountNote } = useNotification();

	useEffect(() => {
		const getLeaseWithSocket = () => {
			const socket = getSocket(
				'wss://admin-api.streamset.pro/v2/sessions/' + id + '/logs',
			);
			socket.onmessage = (message) => {
				message = message.data;

				if (!message) return mountNote('error', 'Empty message!!!');
				setLogs((prev) => prev + ' \n ' + message);

				setSocketLoading(false);
			};
			return ()=>{
				socket.onclose = ()=>{};
				socket.close();
			}
		};
		return getLeaseWithSocket();

		// eslint-disable-next-line
	}, []);
	return (
		<div>
			<h4>Operation LOGs</h4>

			<div className={`logs ${socketLoading ? 'containerLoading' : ''}`}>
				{logs.split('\n').map((line, key) => (
					<p key={key}>{line}</p>
				))}
			</div>
		</div>
	);
};

export default Logs;
