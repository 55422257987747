import { useState } from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Button from '../../componants/Base/Button';
import Input from '../../componants/Base/Input';
import useFetch from '../../hooks/useFetch';
import useNotification from '../../hooks/useNotification';
import useSocket from '../../hooks/useSocket';

const EditEncoder = () => {
	// hooks and utils
	const { id } = useParams();
	const { getSocket, setSocketLoading, socketLoading } = useSocket();
	const { mountNote } = useNotification();
	const { postReq } = useFetch();

	//states
	const [encoder, setEncoder] = useState({
		name: '',
		description: '',
		model: '',
		logo: '',
		inputs: [],
	});
	const [loading, setLoading] = useState(false);

	//handle state

	const handleInputs = (id, key, value) => {
		let edited = { ...encoder };
		edited.inputs[id][key] = value;
		setEncoder(edited);
	  };
	  const deleteInput = (id) => {
		let edited = { ...encoder };
		edited.inputs.splice(id,1);
		setEncoder(edited);
	  };
	  const addInput = () => {
		let edited = { ...encoder };
		edited.inputs.push({
		  name: ""
		});
		setEncoder(edited);
	  };

	//get socket (out of useEffect so i can use it in reset)
	const getEncoderSocket = () => {
		const socket = getSocket(
			'wss://admin-api.streamset.pro/v2/encoders/' + id,
		);
		socket.onmessage = (message) => {
			let body;
			try {
				body = JSON.parse(message.data);
			} catch (e) {
				mountNote('error', e.message);
			}
			if (!body) return mountNote('error', 'Empty JSON!!!');

			if (body.shape === 'virtualset.v1.encoder') {
				setEncoder(body.encoder);
			}
			setSocketLoading(false);
		};

		return ()=>{
			socket.onclose = ()=>{};
			socket.close();
		}
	};

	//submit changes

	const submit = async () => {
		setLoading(true);
		let dataload = { ...encoder };
		delete dataload._id;

		const res = await postReq('encoders/' + id, dataload);
		res !== 'error' && mountNote('success', 'Encoder edited succesfuly');
		setLoading(false);
	};

	useEffect(() => {
		return getEncoderSocket(); // eslint-disable-next-line
	}, []);

	return (
		<div
			style={{ padding: 50 }}
			className={socketLoading ? 'containerLoading' : ''}>
			<h1>
				Encoder
				<span style={{ color: '#FF0000' }}> {encoder.code}</span>
			</h1>
			<form
				style={{
					display: 'flex',
					padding: '12px 0',
					justifyContent: 'space-between',
				}}>
				<div style={{ width: '30%' }}>
					<Input
						containerStyle={style.inpCont}
						title='Name'
						type='text'
						value={encoder.name}
						onChange={(e) => setEncoder({ ...encoder, name: e })}
					/>
					<Input
						containerStyle={style.inpCont}
						title='Description'
						type='text'
						value={encoder.description}
						onChange={(e) => setEncoder({ ...encoder, description: e })}
					/>
					
				</div>
				<div style={{ width: '30%' }}>
				<Input
						containerStyle={style.inpCont}
						title='MODEL'
						type='text'
						value={encoder.model}
						onChange={(e) => setEncoder({ ...encoder, model: e })}
					/>
					<Input
						containerStyle={style.inpCont}
						title='LOGO'
						type='text'
						value={encoder.logo}
						onChange={(e) => setEncoder({ ...encoder, logo: e })}
					/>
				</div>
				<div style={{ width: '30%' }}>
					{encoder.inputs.map((input, i) => (
						<div key={input._id}>
							<div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "nowrap",
                  alignItems: "center",
                }}
              >
                <div className="player-index">IN: {i + 1}</div>
                <Input
                  value={input.name}
                  containerStyle={{ width: "70%" }}
                  onChange={(e) => handleInputs(i, "name", e)}
                />
								<svg
									style={{
										width: 25,
										fill: 'white',
										margin: 15,
										marginLeft: 'auto',
									}}
									onClick={() => deleteInput(i)}
									xmlns='http://www.w3.org/2000/svg'
									viewBox='0 0 448 512'>
									<path d='M268 416h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12zM432 80h-82.41l-34-56.7A48 48 0 0 0 274.41 0H173.59a48 48 0 0 0-41.16 23.3L98.41 80H16A16 16 0 0 0 0 96v16a16 16 0 0 0 16 16h16v336a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128h16a16 16 0 0 0 16-16V96a16 16 0 0 0-16-16zM171.84 50.91A6 6 0 0 1 177 48h94a6 6 0 0 1 5.15 2.91L293.61 80H154.39zM368 464H80V128h288zm-212-48h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12z' />
								</svg>
							</div>
						</div>
					))}
					<button
						className='add'
						onClick={(e) => {
							e.preventDefault();
							addInput();
						}}>
						ADD VIDEO STREAM
						<span style={{ position: 'absolute' }}></span>
					</button>
				</div>
			</form>

			<div className='buttons'>
				<Button
					disabled={loading}
					title='RESET'
					func={() => getEncoderSocket()}
				/>
				<Button
					disabled={loading}
					title='SAVE'
					loading={loading}
					filled
					func={() => submit()}
				/>
			</div>
		</div>
	);
};
const style = {
	inpCont: {
		margin: 0,
		marginBottom: 15,
	},
	buttons: {
		margin: 'auto',
		width: 'fit-content',
	},
};
export default EditEncoder;
