import { useState } from "react";
import { useParams } from "react-router-dom";
import Button from "../componants/Base/Button";
import Input from "../componants/Base/Input";
import Logs from "../componants/LinkEncoder/Logs";
import Status from "../componants/LinkEncoder/Status";
import useFetch from "../hooks/useFetch";
import useNotification from "../hooks/useNotification";
import { useNavigate } from "react-router-dom";

const LinkEncoder = () => {
  const { id } = useParams();
  const { postReq } = useFetch();
  const { mountNote } = useNotification();
  const navigate = useNavigate();

  const [values, setValues] = useState({
    code: "",
    otp: "",
  });
  const [pageLoader, setPageLoader] = useState(false);

  const submit = async () => {
    setPageLoader(true);
    let res = await postReq("sessions/" + id + "/encoder", values);

    if (res.ok) {
      mountNote("success", "Encoders connected Succesfully");
    }
    setPageLoader(false);
  };

  const unlinkSubmit = async () => {
    setPageLoader(true);
    let res = await postReq("sessions/" + id + "/unlink-encoder");

    if (res.ok) {
      mountNote("success", "Encoders disconnected Succesfully");
    }
    setPageLoader(false);
  };

  return (
    <div
      style={{ padding: 50 }}
      className={pageLoader ? "containerLoading" : ""}
    >
      <h1>ENCODER SELECTION</h1>
      <div
        style={{
          display: "flex",
          padding: "12px 0",
          justifyContent: "space-between",
        }}
      >
        <div style={{ width: "20%" }}>
          <Input
            type="text"
            placeholder="Encoder code"
            value={values.code}
            title="Encoder"
            onChange={(e) => setValues({ ...values, code: e })}
          />
          <Input
            type="text"
            placeholder="One time password"
            value={values.otp}
            title="OTP"
            onChange={(e) => setValues({ ...values, otp: e })}
          />
          <div style={{ textAlign: "center", marginTop: 18, width: "100%",justifyContent: "center", alignItems: "center", display:"flex", flexWrap:"wrap" }}>
            <Button title="LINK ENCODER" filled func={submit} style={{marginTop: "10px", minWidth: "140px"}} />
            <Button title="CLEAR ENCODERS" func={unlinkSubmit} style={{marginTop: "10px", minWidth: "140px"}} />
          </div>
        </div>
        <div style={{ width: "75%" }}>
          <Status id={id} />
          <Logs id={id} />
          <div style={{ textAlign: "center", marginTop: 28 }}>
            <Button title="Close" func={() => navigate(-1)} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LinkEncoder;
