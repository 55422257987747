import { useDispatch, useSelector } from 'react-redux';
import { setFilters } from '../../Redux/Reducers/EncodersFilters';

const StatusFilter = () => {
	const filters = useSelector((state) => state.EncodersFilters.filters);
	const dispatch = useDispatch();

	return (
		<div className='status'>
			<h4>Position</h4>
			<div className='statusContainer'>
				<label>
					<input
						defaultChecked={filters['New']}
						onChange={(e) =>
							dispatch(setFilters({ ...filters, New: e.target.checked }))
						}
						type='checkbox'
					/>
					New
				</label>
				<label>
					<input
						defaultChecked={filters['Online']}
						type='checkbox'
						onChange={(e) =>
							dispatch(setFilters({ ...filters, Online: e.target.checked }))
						}
					/>
					Online
				</label>
				<label>
					<input
						defaultChecked={filters['Offline']}
						type='checkbox'
						onChange={(e) =>
							dispatch(setFilters({ ...filters, Offline: e.target.checked }))
						}
					/>
					Offline
				</label>
			</div>
		</div>
	);
};

export default StatusFilter;
