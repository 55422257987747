const Input = ({
	title,
	onChange,
	type,
	containerStyle,
	value,
	disabled,
	min,
	placeholder,
	filled,
}) => {
	return (
		<div
			className={`customInput ${filled ? 'filledInput' : ''}`}
			style={containerStyle}>
			<p>{title || ''}</p>
			<input
				className='input'
				disabled={disabled}
				value={value || ''}
				type={type}
				min={min}
				placeholder={placeholder}
				onChange={(e) => onChange(e.target.value)}
			/>
		</div>
	);
};

export default Input;
