import React from "react";
import { useParams } from "react-router-dom";

const baseUrl = "https://admin.virtualset.it/v1/";

class EventStatsOld extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "id",
      directorId: "String",
      privateId: "String",
      channel: "String",
      name: "String",
      hasChat: true,
      hasAudio: false,
      chatExpire: 0,
      logo: "https://api.virtualset.it/cdn/loghi/moviechrome.png",
      inizio: Date.now(),
      fine: Date.now(),
      players: [],
      playerSettings: {
        baseUrl: "String",
        buffer: 15,
        syncBuffer: 15,
        sync: true,
      },
      spectators: [],
      orientation: true,
    };
  }

  componentDidMount() {
    this.connect();
  }

  render() {
    return (
      <div className="card main-card">
        <div className="row">
          <div className="col-12 stats-margins">
            <h3 className="mb-3">.</h3>
            <h3 className="mb-3">Connessioni: {this.state.name}</h3>
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>NOME</th>
                    <th>IP</th>
                    <th>BROWSER</th>
                    <th>DISPOSITOVO</th>
                    <th>LAST SEEN</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.spectators?.map((element, key) => (
                    <tr key={key}>
                      <td>{element.nome}</td>
                      <td>{element.ip}</td>
                      <td>{element.browser}</td>
                      <td>{element.devName}</td>
                      <td className={getRowClass(element)}>
                        {getElapsedTime(element)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
  connect() {
    const token = localStorage.getItem("virtualset2-admin-token") || "";
    this.ws = new WebSocket(
      `wss://admin-api.streamset.pro/v2/sessions/${this.props.id}/stats`,
      token
    );
    this.ws.onopen = () => {
      console.log("wss connected!", this.props.id);
    };

    this.ws.onmessage = (e) => {
      let body = {};
      try {
        body = JSON.parse(e.data);
        console.log(body);
      } catch (e) {
        console.log(e.message);
      }
      if (!body) return console.log("Empty JSON!!!");

      if (body.shape == "virtualset.v1.session.stats") {
        if (body.session) {
          body.session.spectators.sort((a, b) => {
            let aTimeDiff =
              new Date().getTime() - new Date(a.lastSeen).getTime() + 1000;
            aTimeDiff /= 1000;
            let aSeconds =
              Math.round(aTimeDiff) && Math.round(aTimeDiff) < 60 ? 0 : 1;

            let bTimeDiff =
              new Date().getTime() - new Date(b.lastSeen).getTime() + 1000;
            bTimeDiff /= 1000;
            let bSeconds =
              Math.round(bTimeDiff) && Math.round(bTimeDiff) < 60 ? 0 : 1;

            return aSeconds - bSeconds;
          });
          body.session.spectators.forEach((element) => {
            let timeDiff =
              new Date().getTime() - new Date(element.lastSeen).getTime();
            timeDiff /= 1000;

            element.devName =
              element.device && element.device.includes(")")
                ? element.device.split(")")[0].split("(").join(" - ")
                : element.device;
          });

          this.setState(body.session || {});
        }
      }
    };

    this.ws.onclose = (e) => {
      console.log(
        "Socket is closed. Reconnect will be attempted in 1 second.",
        e.reason
      );
      setTimeout(() => {
        this.connect();
      }, 1000);
    };

    this.ws.onerror = (err) => {
      console.error(
        "Socket encountered error: ",
        err.message,
        "Closing socket"
      );
      this.ws.close();
    };
  }

  componentWillUnmount() {
    if (this.ws)
      this.ws.onclose = (e) => {
        console.log("Socket is safely closed.", e.reason);
      };

    if (this.ws) this.ws.close();
  }
}

function getRowClass(element) {
  let timeDiff = new Date().getTime() - new Date(element.lastSeen).getTime();
  timeDiff /= 1000;
  let seconds = Math.round(timeDiff + 1);
  return seconds && seconds < 60 ? "ok-format" : "err-format";
}

function getElapsedTime(element) {
  let timeDiff = new Date().getTime() - new Date(element.lastSeen).getTime();
  timeDiff /= 1000;
  let seconds = Math.round(timeDiff + 1);
  return seconds && seconds < 300 ? seconds + " secondi fa" : "disconnesso";
}

const EventStats = () => {
  const { id } = useParams();
  return <EventStatsOld id={id} />;
};

export default EventStats;
