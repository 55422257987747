import { useDispatch, useSelector } from 'react-redux';
import { setSortBy } from '../../Redux/Reducers/Filters';

const Sorting = () => {
	const sortBy = useSelector((state) => state.filters.sortBy);
	const dispatch = useDispatch();

	const Arrow = ({ desc }) => (
		<svg
			style={{
				height: 12,
				fill: 'white',
				transform: desc && 'rotate(180deg)',
				marginLeft: 5,
			}}
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 320 512'>
			<path d='M143 256.3L7 120.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0L313 86.3c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.4 9.5-24.6 9.5-34 .1zm34 192l136-136c9.4-9.4 9.4-24.6 0-33.9l-22.6-22.6c-9.4-9.4-24.6-9.4-33.9 0L160 352.1l-96.4-96.4c-9.4-9.4-24.6-9.4-33.9 0L7 278.3c-9.4 9.4-9.4 24.6 0 33.9l136 136c9.4 9.5 24.6 9.5 34 .1z' />
		</svg>
	);

	const filters = ['Start/Asc', 'Start/Desc', 'End/Asc', 'End/Desc'];

	return (
		<div className='status'>
			<h4>Status</h4>
			<div className='statusContainer'>
				{filters.map((filter, i) => (
					<label key={i}>
						<input
							defaultChecked={filter === sortBy}
							className='radioInput'
							onChange={(e) => dispatch(setSortBy(e.target.value))}
							type='radio'
							name='option'
							value={filter}
						/>
						{filter.replace('/Asc', '').replace('/Desc', '')}
						{i % 2 ? <Arrow /> : <Arrow desc={true} />}
					</label>
				))}
			</div>
		</div>
	);
};

export default Sorting;
