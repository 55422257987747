import moment from 'moment';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import Button from '../../componants/Base/Button';
import Input from '../../componants/Base/Input';
import Switch from '../../componants/Base/Switch';
import useFetch from '../../hooks/useFetch';
import useNotification from '../../hooks/useNotification';
import useSocket from '../../hooks/useSocket';

const Session = () => {
	const location = useLocation();
	const { postReq } = useFetch();
	const { getSocket, socketLoading, setSocketLoading } = useSocket();
	const navigate = useNavigate();
	const { mountNote } = useNotification();

	const [streams, setStreams] = useState([]);
	const [loading, setLoading] = useState(false);
	const [values, setValues] = useState({
		name: '',
		inizio: moment().format('YYYY-MM-DDThh:mm'),
		fine: moment().add(3, 'months').format('YYYY-MM-DDThh:mm'),
		hasChat: false,
		hasAudio: false,
		logo: '',
		playerSettings: {
			baseUrl: '',
			buffer: 0,
			syncBuffer: 0,
			sync: true,
		},
		players: streams,
	});

	const updateStream = (field, value, index) => {
		let players = [...values.players];
		players.map((player) =>
			players.indexOf(player) === index
				? (player[field] = value)
				: player[field],
		);
		setStreams(players);
	};

	const submit = async () => {
		setLoading(true);
		let url = 'sessions/' + values._id;
		let res = await postReq(url, values);
		if (res !== 'error') {
			mountNote('success', 'Session updated successfully');
		}
		setLoading(false);
	};

	useEffect(() => {
		setValues({ ...values, players: streams }); // eslint-disable-next-line
	}, [streams]);

	useEffect(() => {
		const getSessionWithSocket = () => {
			const socket = getSocket(
				`wss://admin-api.streamset.pro/v2/sessions/${location.state._id}`,
			);

			socket.onmessage = (message) => {
				let response;
				setSocketLoading(false);
				try {
					response = JSON.parse(message.data);
					if (response.shape === 'virtualset.v1.session') {
						let data = response.session;

						setValues({
							name: data.name,
							_id: data._id,
							inizio: data.inizio,
							fine: data.fine,
							hasChat: data.hasChat,
							hasAudio: data.hasAudio,
							accessCode: data.id,
							directorId: data.directorId,
							privateId: data.privateId,
							logo: data.logo,
							players: data.players,
						});
					}
				} catch (error) {
					return;
				}
			};

			return ()=>{
				socket.onclose = ()=>{};
				socket.close();
			}
		};
		if (location.state) {
			return getSessionWithSocket();
		} else {
			navigate('/sessions');
		} // eslint-disable-next-line
	}, [location]);

	return (
		<div
			style={{ padding: 50 }}
			className={socketLoading ? 'containerLoading' : ''}>
			<h1>
				<span style={{ color: '#FF0000' }}>Edit</span> Session
			</h1>
			<form
				action=''
				style={{
					display: 'flex',
					padding: '12px 0',
					justifyContent: 'space-between',
				}}>
				<div style={{ width: '30%' }}>
					<Input
						containerStyle={style.inpCont}
						title='Name'
						type='text'
						value={values.name}
						onChange={(e) => setValues({ ...values, name: e })}
					/>
					<Input
						disabled
						containerStyle={style.inpCont}
						title='Access Code Standard'
						type='text'
						value={values.accessCode}
					/>
					<Input
						disabled
						containerStyle={style.inpCont}
						title='Access Code Director'
						type='text'
						value={values.directorId}
					/>
					<Input
						disabled
						containerStyle={style.inpCont}
						title='Access Code Incognito'
						type='text'
						value={values.privateId}
					/>
					
				</div>
				<div style={{ width: '30%' }}>
					<Switch
						title='Text Chat'
						checked={values.hasChat || false}
						onChange={(e) => setValues({ ...values, hasChat: e })}
					/>
					<Switch
						title='Audio-Video Chat'
						checked={values.hasAudio || false}
						onChange={(e) => setValues({ ...values, hasAudio: e })}
					/>
					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<Input
							containerStyle={{ width: '49%' }}
							title='Inizio'
							value={moment(values.inizio).format('YYYY-MM-DDThh:mm')}
							type='datetime-local'
							onChange={(e) => setValues({ ...values, inizio: e })}
						/>
						<Input
							containerStyle={{ width: '49%' }}
							title='Fine'
							value={moment(values.fine).format('YYYY-MM-DDThh:mm')}
							type='datetime-local'
							onChange={(e) => setValues({ ...values, fine: e })}
						/>
					</div>
					<Input
						containerStyle={style.inpCont}
						title='Logo (absolute url with https://)'
						value={values.logo}
						type='url'
						onChange={(e) =>
							setValues({
								...values,
								logo: e,
							})
						}
					/>
				</div>
				<div style={{ width: '30%' }}>
					{values.players &&
						values.players.length > 0 &&
						values.players.map((stream, i) => (
							<div style={{ marginBottom: 15}} key={i}>
								<div
									style={{ display: 'flex', justifyContent: 'space-between' }}>
									<Input
										value={stream.name}
										containerStyle={{ width: '100%' }}
										title={'Stream: ' + (i+1)}
										onChange={(e) => updateStream('name', e, i)}
									/>
								</div>
								<div
									style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
									<Switch
										containerStyle={{
											...style.inpCont,
											width: 'calc(50% - 10px)',
										}}
										checked={stream.director || false}
										title='Director only'
										onChange={(e) => updateStream('director', e, i)}
									/>
									<Switch
										containerStyle={{
											width: 'calc(50% - 10px)',
										}}
										checked={stream.hidden || false}
										title='Hidden'
										onChange={(e) => updateStream('hidden', e, i)}
									/>
								</div>
							</div>
						))}
				</div>
			</form>
			<div className='buttons'>
				<Button
					title='ENCODER'
					func={() => navigate('/linkEncoder/' + location.state._id)}
				/>
				<Button title='SAVE' loading={loading} filled func={submit} />
			</div>
		</div>
	);
};

const style = {
	inpCont: {
		margin: 0,
		marginBottom: 15,
	},
	buttons: {
		margin: 'auto',
		width: 'fit-content',
	},
};
export default Session;
