import jwtDecode from 'jwt-decode';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import Input from '../../componants/Base/Input';
import Select from '../../componants/Base/Select';
import PurchaseTime from '../../componants/sessions/PurchaseTime';
import Switch from '../../componants/Base/Switch';
import useFetch from '../../hooks/useFetch';
import useNotification from '../../hooks/useNotification';
import useSocket from '../../hooks/useSocket';
import Button from '../../componants/Base/Button';
const CustomerSession = () => {
	const location = useLocation();
	const { postReq } = useFetch();
	const navigate = useNavigate();
	const user = jwtDecode(localStorage.getItem('virtualset2-admin-token'));
	const { mountNote } = useNotification();
	const { getSocket, setSocketLoading, socketLoading } = useSocket();

	const [streams, setStreams] = useState([]);
	const [values, setValues] = useState({
		name: '',
		_id: '',
		directorId: '',
		privateId: '',
		accessCode: '',
		inizio: moment().format('YYYY-MM-DDThh:mm'),
		fine: moment().add(3, 'months').format('YYYY-MM-DDThh:mm'),
		hasChat: false,
		hasAudio: false,
		logo: '',
		simpleStreamSettings: '',
		playerSettings: {
			baseUrl: '',
			buffer: 0,
			syncBuffer: 0,
			sync: true,
		},
		players: streams,
	});
	const [addedDays, setAddedDays] = useState({
		show: false,
		count: 1,
	});
	const [loading, setLoading] = useState(false);

	const updateStream = (field, value, index) => {
		let players = [...values.players];
		players.map((player) =>
			players.indexOf(player) === index
				? (player[field] = value)
				: player[field],
		);
		setStreams(players);
	};

	const submit = async () => {
		setLoading(true);
		let url = 'sessions/' + values._id;
		delete values.playerSettings;
		try {
			const res = await postReq(url, values);

			if (res !== 'error') {
				mountNote('success', 'Session updated successfully');
			}
		} catch (error) {
			mountNote('error', error.message);
		}
		setLoading(false);
	};

	useEffect(() => {
		setValues({ ...values, players: streams }); // eslint-disable-next-line
	}, [streams]);

	useEffect(() => {
		setSocketLoading(true);
		const getSessionWithSocket = () => {
			const socket = getSocket(
				`wss://admin-api.streamset.pro/v2/sessions/${location.state._id}`,
			);

			socket.onmessage = (message) => {
				let response;

				try {
					response = JSON.parse(message.data);
					setSocketLoading(false);
					if (response.shape === 'virtualset.v1.session') {
						let data = response.session;
						setValues({
							name: data.name,
							_id: data._id,
							inizio: data.inizio,
							fine: data.fine,
							hasChat: data.hasChat,
							hasAudio: data.hasAudio,
							accessCode: data.id,
							directorId: data.directorId,
							privateId: data.privateId,
							logo: data.logo,
							playerSettings: {
								baseUrl: data.playerSettings.baseUrl,
								buffer: data.playerSettings.buffer,
								syncBuffer: data.playerSettings.syncBuffer,
								sync: data.playerSettings.sync,
							},
							players: data.players,
						});
					}
				} catch (error) {
					return;
				}
			};

			return ()=>{
				socket.onclose = ()=>{};
				socket.close();
			}
		};
		if (location.state) {
			return getSessionWithSocket();
		} else {
			navigate('/sessions');
		} // eslint-disable-next-line
	}, [location]);
	return (
		<div
			style={{ padding: 50 }}
			className={socketLoading ? 'containerLoading' : ''}>
			<h1>
				<span style={{ color: '#FF0000' }}>Edit</span> Session
			</h1>
			<form
				action=''
				style={{
					display: 'flex',
					padding: '12px 0',
					justifyContent: 'space-between',
				}}>
				<div style={{ width: '30%' }}>
					<Input
						containerStyle={style.inpCont}
						title='Name'
						type='text'
						value={values.name}
						onChange={(e) => setValues({ ...values, name: e })}
					/>
					<Input
						disabled
						containerStyle={style.inpCont}
						title='Access Code Standard'
						type='text'
						value={values.accessCode}
					/>
					<Input
						disabled
						containerStyle={style.inpCont}
						title='Access Code Director'
						type='text'
						value={values.directorId}
					/>
					<Input
						disabled
						containerStyle={style.inpCont}
						title='Access Code Incognito'
						type='text'
						value={values.privateId}
					/>
				</div>
				<div style={{ width: '30%' }}>
					<Switch
						title='Chat'
						checked={values.hasChat || false}
						onChange={(e) => setValues({ ...values, hasChat: e })}
					/>
					<Switch
						title='Zoom'
						checked={values.hasAudio || false}
						onChange={(e) => setValues({ ...values, hasAudio: e })}
					/>

					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<Input
							disabled={!user.recurrent}
							containerStyle={{ width: '49%' }}
							title='Start'
							value={moment(values.inizio).format('YYYY-MM-DDThh:mm')}
							type='datetime-local'
							onChange={(e) => setValues({ ...values, inizio: e })}
						/>
						<Input
							disabled={!user.recurrent}
							containerStyle={{ width: '49%' }}
							title='End'
							value={moment(values.fine).format('YYYY-MM-DDThh:mm')}
							type='datetime-local'
							onChange={(e) => setValues({ ...values, fine: e })}
						/>
					</div>
					<Select
						title='Operative Modes'
						value={values.simpleStreamSettings}
						select={(e) => setValues({ ...values, simpleStreamSettings: e })}>
						<option value='ll'>low latency</option>
						<option value='normal'>normal</option>
						<option value='safe'>safe</option>
					</Select>
					{!user.recurrent && (
						<button
							className='add'
							onClick={(e) => {
								e.preventDefault();
								setAddedDays({ ...addedDays, show: true });
							}}>
							<span style={{ position: 'absolute' }}></span>
							Add More time
						</button>
					)}
					{addedDays.show && (
						<div
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'flex-end',
							}}>
							<Input
								min='1'
								containerStyle={{ width: '49%', marginBottom: 0 }}
								title='PURCHASE DAYS'
								value={addedDays.count}
								type='number'
								onChange={(e) => setAddedDays({ ...addedDays, count: e })}
							/>
							<PurchaseTime
								style={{ ...style.button, ...style.fillBtn, display: 'flex' }}
								id={values._id}
								count={addedDays.count}
							/>
							<button
								style={{ ...style.button }}
								onClick={(e) => {
									e.preventDefault();
									setAddedDays({ ...addedDays, show: false });
								}}>
								Cancel
							</button>
						</div>
					)}
				</div>
				<div style={{ width: '30%' }}>
					{values.players &&
						values.players.length > 0 &&
						values.players.map((stream, i) => (
							<div style={{ margin: 15 }} key={i}>
								<div
									style={{
										display: 'flex',
										justifyContent: 'space-between',
									}}>
									<Input
										value={stream.name}
										containerStyle={{ width: '49%' }}
										title='Stream Name'
										onChange={(e) => updateStream('name', e, i)}
									/>
									<Input
										disabled
										containerStyle={{ width: '49%' }}
										title='Stream ID'
										value={stream.id}
									/>
								</div>
								<div
									style={{
										display: 'flex',
										justifyContent: 'space-between',
									}}>
									<Switch
										containerStyle={{
											...style.inpCont,
											width: 'calc(50% - 10px)',
										}}
										checked={stream.director}
										title='Director'
										onChange={(e) => updateStream('director', e, i)}
									/>
									<Switch
										containerStyle={{
											...style.inpCont,
											width: 'calc(50% - 10px)',
										}}
										checked={stream.hidden}
										title='Hidden'
										onChange={(e) => updateStream('hidden', e, i)}
									/>
								</div>
							</div>
						))}
				</div>
			</form>

			<div style={style.buttons}>
				<Button
					title='ENCODERS'
					func={() => navigate('/linkEncoder/' + location.state._id)}
				/>
				<Button title='SAVE' loading={loading} filled func={submit} />
			</div>
		</div>
	);
};
const style = {
	inpCont: {
		margin: 0,
		marginBottom: 15,
	},
	buttons: {
		margin: 'auto',
		width: 'fit-content',
	},
	button: {
		display: 'inline-flex',
		alignItems: 'center',
		justifyContent: 'space-evenly',
		background: 'transparent',
		padding: '7px 12px',
		borderRadius: '4px',
		fontWeight: '700',
		margin: '0 10px',
		cursor: 'pointer',
		minWidth: 110,
		border: '2px solid red',
		color: 'white',
	},
	fillBtn: {
		background: 'red',
		color: 'white',
	},
};

export default CustomerSession;
