import { useNavigate } from 'react-router-dom';

const EncodersTable = ({ data }) => {
	const navigate = useNavigate();

	return (
		<div
			style={{
				width: '100%',
			}}>
			{data === 'error' ? (
				<h1
					className='Finished'
					style={{
						color: '#5c0a0a',
						backgroundColor: '#fed5d5',
						height: '350px',
						lineHeight: '350px',
						textAlign: 'center',
					}}>
					Something went wrong , please try again later!
				</h1>
			) : (
				<table className='sessionsTable' style={{ position: 'relative' }}>
					<thead>
						<tr>
							<th>
								<span>Name</span>
							</th>
							<th>
								<span>Description (descriptio)</span>
							</th>
							<th>
								<span>Status</span>
							</th>
							<th>
								<span>Code</span>
							</th>
							<th>
								<span>Model</span>
							</th>
							<th>
								<span>Edit</span>
							</th>
						</tr>
					</thead>
					<tbody>
						{Array.isArray(data) &&
							data?.map((row, i) => (
								<tr key={i}>
									<td>
										<span> {row.name}</span>
									</td>
									<td>
										<span>{row.description}</span>
									</td>
									<td>
										<span className={`tag ${row.status}`}>{row.status}</span>
									</td>
									<td>
										<span>{row.code}</span>
									</td>
									<td>
										<span>{row.model}</span>
									</td>
									<td>
										<span style={{ display: 'flex' }}>
											<svg
												onClick={() => {
													navigate(`/encoder/${row._id}`);
												}}
												xmlns='http://www.w3.org/2000/svg'
												viewBox='0 0 576 512'>
												<path d='M402.3 344.9l32-32c5-5 13.7-1.5 13.7 5.7V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h273.5c7.1 0 10.7 8.6 5.7 13.7l-32 32c-1.5 1.5-3.5 2.3-5.7 2.3H48v352h352V350.5c0-2.1.8-4.1 2.3-5.6zm156.6-201.8L296.3 405.7l-90.4 10c-26.2 2.9-48.5-19.2-45.6-45.6l10-90.4L432.9 17.1c22.9-22.9 59.9-22.9 82.7 0l43.2 43.2c22.9 22.9 22.9 60 .1 82.8zM460.1 174L402 115.9 216.2 301.8l-7.3 65.3 65.3-7.3L460.1 174zm64.8-79.7l-43.2-43.2c-4.1-4.1-10.8-4.1-14.8 0L436 82l58.1 58.1 30.9-30.9c4-4.2 4-10.8-.1-14.9z' />
											</svg>
										</span>
									</td>
								</tr>
							))}
					</tbody>
				</table>
			)}
			{data === 'loading' && (
				<div
					style={{
						padding: '50px 0',
						width: '100%',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						background: '#dcdcdc1c',
					}}>
					<h3>Loading</h3>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='38'
						height='38'
						stroke='#fff'
						style={{ height: '20px', cursor: 'auto' }}
						viewBox='0 0 38 38'>
						<g
							fill='none'
							fillRule='evenodd'
							strokeWidth='2'
							transform='translate(1 1)'>
							<circle cx='18' cy='18' r='18' strokeOpacity='0.5'></circle>
							<path d='M36 18c0-9.94-8.06-18-18-18'>
								<animateTransform
									attributeName='transform'
									dur='1s'
									from='0 18 18'
									repeatCount='indefinite'
									to='360 18 18'
									type='rotate'></animateTransform>
							</path>
						</g>
					</svg>
				</div>
			)}
		</div>
	);
};

export default EncodersTable;
