import axios from 'axios';
import useNotification from './useNotification';

const useFetch = () => {
	const baseUrl = process.env.REACT_APP_BASE_URL;
	const token = localStorage.getItem('virtualset2-admin-token');
	const { mountNote } = useNotification();
	const getData = async (url) => {
		let data;
		await axios(baseUrl + url, {
			headers: { 'x-auth-token': token },
		})
			.then((res) => {
				data = res.data;
			})
			.catch((err) => {
				const message = err.response ? err.response.data : err.message;
				mountNote('error', message);
				data = 'error';
			});
		return data;
	};
	const postReq = async (url, dataLoad) => {
		let data;

		await axios
			.post(baseUrl + url, dataLoad, {
				headers: { 'x-auth-token': token },
			})
			.then((res) => {
				data = res.data;
			})
			.catch((err) => {
				const message = err.response ? err.response.data : err.message;
				mountNote('error', message);
				data = 'error';
			});
		return data;
	};
	const deleteReq = async (url) => {
		let data;
		await axios
			.delete(baseUrl + url, {
				headers: { 'x-auth-token': token },
			})
			.then((res) => {
				data = res.data;
			})
			.catch((err) => {
				const message = err.response ? err.response.data : err.message;
				mountNote('error', message);
				data = message;
			});
		return data;
	};
	return { getData, postReq, deleteReq };
};

export default useFetch;
