import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useFetch from '../hooks/useFetch';
import useNotification from '../hooks/useNotification';

const PaymentVerification = () => {
	const navigate = useNavigate();
	const { postReq } = useFetch();
	const { search } = useLocation();
	const { mountNote } = useNotification();

	const session_id = new URLSearchParams(search).get('session_id');

	useEffect(() => {
		if (!session_id) return navigate('/sessions');
		const confirm = async () => {
			const data = await postReq('stripe-success', {
				session_id,
			});
			if (data.ok) {
				if (data.jwt) {
					localStorage.setItem('virtualset2-admin-token', data.jwt);
					return navigate('/user');
				}
				mountNote('success', 'Payment was succesful');
				navigate('/sessions');
			} else {
				mountNote('error', 'Payment was not succesful , please try again');
				navigate('/sessions');
			}
		};
		confirm(); // eslint-disable-next-line
	}, []);
	return (
		<h2 className='paymentLoading'>Please wait while we redirect you back</h2>
	);
};

export default PaymentVerification;
