import { useState } from 'react';
import useFetch from '../../hooks/useFetch';
import useNotification from '../../hooks/useNotification';

const Reload = ({ id }) => {
	const { postReq } = useFetch();
	const { mountNote } = useNotification();

	const [loading, setLoading] = useState(false);

	const reload = async () => {
		setLoading(true);
		const res = await postReq(`sessions/${id}/reload`, {
			manual: true,
		});
		res.ok && mountNote('success', 'Reloaded succesfully');

		setLoading(false);
	};
	return !loading ? (
		<svg
			onClick={reload}
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 512 512'>
			<path d='M212.333 224.333H12c-6.627 0-12-5.373-12-12V12C0 5.373 5.373 0 12 0h48c6.627 0 12 5.373 12 12v78.112C117.773 39.279 184.26 7.47 258.175 8.007c136.906.994 246.448 111.623 246.157 248.532C504.041 393.258 393.12 504 256.333 504c-64.089 0-122.496-24.313-166.51-64.215-5.099-4.622-5.334-12.554-.467-17.42l33.967-33.967c4.474-4.474 11.662-4.717 16.401-.525C170.76 415.336 211.58 432 256.333 432c97.268 0 176-78.716 176-176 0-97.267-78.716-176-176-176-58.496 0-110.28 28.476-142.274 72.333h98.274c6.627 0 12 5.373 12 12v48c0 6.627-5.373 12-12 12z' />
		</svg>
	) : (
		<svg xmlns='http://www.w3.org/2000/svg' stroke='#fff' viewBox='0 0 38 38'>
			<g
				fill='none'
				fillRule='evenodd'
				strokeWidth='2'
				transform='translate(1 1)'>
				<circle cx='18' cy='18' r='18' strokeOpacity='0.5'></circle>
				<path d='M36 18c0-9.94-8.06-18-18-18'>
					<animateTransform
						attributeName='transform'
						dur='1s'
						from='0 18 18'
						repeatCount='indefinite'
						to='360 18 18'
						type='rotate'></animateTransform>
				</path>
			</g>
		</svg>
	);
};

export default Reload;
