import jwtDecode from 'jwt-decode';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import PageHeader from '../../componants/Base/PageHeader';
import Sorting from '../../componants/Leases/Sorting';
import StatusFilter from '../../componants/Leases/StatusFilter';
import useNotification from '../../hooks/useNotification';
import useSocket from '../../hooks/useSocket';
import LeasesTable from '../../componants/Leases/LeasesTable';
import { setQuery } from '../../Redux/Reducers/LeasesFilters';
import { useDispatch } from 'react-redux';

const Leases = () => {
	const [tableData, setData] = useState('loading');
	const [baseData, setBaseData] = useState();
	const { mountNote } = useNotification();
	const filters = useSelector((state) => state.LeasesFilters);
	const { getSocket } = useSocket();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const user = jwtDecode(localStorage.getItem('virtualset2-admin-token'));

	const renderStatus = (start, end) => {
		if (moment().isBefore(start)) return 'Future';
		if (moment().isAfter(end)) return 'Closed';
		if (moment().isBetween(start, end)) return 'Out';
	};

	const filterSesions = (arr) => {
		const filtered = [];
		arr?.map((item) => {
			let itemStatus = renderStatus(item.start, item.end);
			if (
				itemStatus === (filters.filters['Future'] && 'Future') ||
				itemStatus === (filters.filters['Closed'] && 'Closed') ||
				itemStatus === (filters.filters['Out'] && 'Out')
			) {
				filtered.push(item);
			}
			return item;
		});
		return filtered;
	};

	const search = (arr) => {
		const featured = [];
		let e = filters.searchQuery?.toLowerCase().trim();
		if (arr && !(!e || /^\s*$/.test(e))) {
			arr.map(
				(item) =>
					(item.encoder.code.toLowerCase().match(e) ||
						item.encoder.name.toLowerCase().match(e) ||
						item?.customer?.nome.toLowerCase().match(e)) &&
					featured.push(item),
			);
			return featured;
		} else {
			return arr;
		}
	};

	useEffect(() => {
		if (!user.superAdmin) {
			return navigate('/sessions');
		}
		const getSessionsWithSocket = () => {
			setData('loading');
			const sessionsSocket = getSocket(
				'wss://admin-api.streamset.pro/v2/leases',
			);
			sessionsSocket.onmessage = (message) => {
				let body;
				try {
					body = JSON.parse(message.data);
				} catch (e) {
					mountNote('error', e.message);
				}
				if (!body) return mountNote('error', 'Empty JSON!!!');

				if (body.shape === 'virtualset.v1.leases') {
					setBaseData(body.leases);
				}
			};

			return ()=>{
				sessionsSocket.onclose = ()=>{};
				sessionsSocket.close();
			}
		};
		return getSessionsWithSocket();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		const oneInAll = () => {
			if (baseData) {
				const searched = search(baseData);
				const filtered = filterSesions(searched);
				const sorted = sortSessions(filtered);
				setData(sorted);
			}
		};
		oneInAll(); // eslint-disable-next-line
	}, [baseData, filters]);

	const sortSessions = (data) => {
		let sorted;
		if (Array.isArray(data)) {
			switch (filters.sortBy) {
				case 'Encoder/Asc':
					sorted = data.sort(function (a, b) {
						if (a.encoder.code < b.encoder.code) {
							return 1;
						}
						if (a.encoder.code > b.encoder.code) {
							return -1;
						}
						return 0;
					});
					break;
				case 'Encoder/Desc':
					sorted = data.sort(function (a, b) {
						if (a.encoder.code < b.encoder.code) {
							return -1;
						}
						if (a.encoder.code > b.encoder.code) {
							return 1;
						}
						return 0;
					});
					break;
				case 'Client/Asc':
					sorted = data.sort(function (a, b) {
						if (a.customer.nome < b.customer.nome) {
							return -1;
						}
						if (a.customer.nome > b.customer.nome) {
							return 1;
						}
						return 0;
					});
					break;
				case 'Client/Desc':
					sorted = data.sort(function (a, b) {
						if (a.customer.nome < b.customer.nome) {
							return 1;
						}
						if (a.customer.nome > b.customer.nome) {
							return -1;
						}
						return 0;
					});
					break;

				case 'Start/Asc':
					sorted = data.sort(function (a, b) {
						if (a.start < b.start) {
							return -1;
						}
						if (a.start > b.start) {
							return 1;
						}
						return 0;
					});
					break;
				case 'Start/Desc':
					sorted = data.sort(function (a, b) {
						if (a.start < b.start) {
							return 1;
						}
						if (a.start > b.start) {
							return -1;
						}
						return 0;
					});
					break;
				case 'End/Asc':
					sorted = data.sort(function (a, b) {
						if (a.end < b.end) {
							return -1;
						}
						if (a.end > b.end) {
							return 1;
						}
						return 0;
					});
					break;
				case 'End/Desc':
					sorted = data.sort(function (a, b) {
						if (a.end < b.end) {
							return 1;
						}
						if (a.end > b.end) {
							return -1;
						}
						return 0;
					});
					break;
				default:
					sorted = data;
			}
		}
		return sorted;
	};

	return (
		<div className='sessions'>
			<PageHeader
				title='Leases'
				placeholder='Search with ENCODER name , code or customer name'
				func={(e) => dispatch(setQuery(e))}
			/>
			<div className='sessionsMainContainer'>
				<div
					className='filters'
					style={{
						pointerEvents: Array.isArray(tableData) ? 'all' : 'none',
						opacity: Array.isArray(tableData) ? 1 : 0.6,
					}}>
					<h3>Filters</h3>
					<StatusFilter filterSesions={filterSesions} />
					<Sorting sortSessions={sortSessions} />
				</div>
				<LeasesTable data={tableData} />
			</div>
			<Link to='/leases/new' className='addSession'></Link>
		</div>
	);
};

export default Leases;
