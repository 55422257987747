import { configureStore } from '@reduxjs/toolkit';
import filtersReducer from './Reducers/Filters';
import EncodersSlice from './Reducers/EncodersFilters';
import CustomersSlice from './Reducers/CustomersFilters';
import LeasesSlice from './Reducers/LeasesFilters';

export default configureStore({
	reducer: {
		filters: filtersReducer,
		EncodersFilters: EncodersSlice,
		CustomersFilters: CustomersSlice,
		LeasesFilters: LeasesSlice,
	},
});
