import { Navigate } from 'react-router';
import Header from './Header';

const Protected = ({ element }) => {
	const token = localStorage.getItem('virtualset2-admin-token');

	return token ? (
		<>
			<Header />
			{element}
		</>
	) : (
		<Navigate replace to='/auth' />
	);
};

export default Protected;
