import jwtDecode from 'jwt-decode';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Sorting from '../../componants/Encoders/Sorting';
import StatusFilter from '../../componants/Encoders/StatusFilter';
import useNotification from '../../hooks/useNotification';
import useSocket from '../../hooks/useSocket';
import EncodersTable from '../../componants/Encoders/EncodersTable';
import PageHeader from '../../componants/Base/PageHeader';
import { setQuery } from '../../Redux/Reducers/EncodersFilters';

const Encoders = () => {
	const [tableData, setData] = useState('loading');
	const [baseData, setBaseData] = useState();
	const { mountNote } = useNotification();
	const filters = useSelector((state) => state.EncodersFilters);
	const { getSocket } = useSocket();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const user = jwtDecode(localStorage.getItem('virtualset2-admin-token'));

	const filterSesions = (arr) => {
		const filtered = [];
		arr?.map((item) => {
			if (
				item.status === (filters.filters['New'] && 'new') ||
				item.status === (filters.filters['Online'] && 'online') ||
				item.status === (filters.filters['Offline'] && 'offline')
			) {
				filtered.push(item);
			}
			return item;
		});
		return filtered;
	};

	const search = (arr) => {
		const featured = [];
		let e = filters.searchQuery?.toLowerCase().trim();
		if (arr && !(!e || /^\s*$/.test(e))) {
			arr.map(
				(item) =>
					(item.name.toLowerCase().match(e) ||
						item.code.toLowerCase().match(e)) &&
					featured.push(item),
			);
			return featured;
		} else {
			return arr;
		}
	};

	useEffect(() => {
		if (!user.superAdmin) {
			return navigate('/sessions');
		}

		const getSessionsWithSocket = () => {
			setData('loading');
			const sessionsSocket = getSocket(
				'wss://admin-api.streamset.pro/v2/encoders',
			);
			sessionsSocket.onmessage = (message) => {
				let body;
				try {
					body = JSON.parse(message.data);
				} catch (e) {
					mountNote('error', e.message);
				}
				if (!body) return mountNote('error', 'Empty JSON!!!');

				if (body.shape === 'virtualset.v1.encoders') {
					setBaseData(body.encoders);
				}
			};

			return ()=>{
				sessionsSocket.onclose = ()=>{};
				sessionsSocket.close();
			}
		};
		return getSessionsWithSocket();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		const oneInAll = () => {
			if (baseData) {
				const searched = search(baseData);
				const filtered = filterSesions(searched);
				const sorted = sortSessions(filtered);
				setData(sorted);
			}
		};
		oneInAll(); // eslint-disable-next-line
	}, [baseData, filters]);

	const sortSessions = (data) => {
		let sorted;
		if (Array.isArray(data)) {
			switch (filters.sortBy) {
				case 'Code/Asc':
					sorted = data.sort(function (a, b) {
						if (a.code < b.code) {
							return -1;
						}
						if (a.code > b.code) {
							return 1;
						}
						return 0;
					});
					break;
				case 'Code/Desc':
					sorted = data.sort(function (a, b) {
						if (a.code < b.code) {
							return 1;
						}
						if (a.code > b.code) {
							return -1;
						}
						return 0;
					});

					break;
				case 'Name/Asc':
					sorted = data.sort(function (a, b) {
						if (a.name < b.name) {
							return -1;
						}
						if (a.name > b.name) {
							return 1;
						}
						return 0;
					});
					break;
				case 'Name/Desc':
					sorted = data.sort(function (a, b) {
						if (a.name < b.name) {
							return 1;
						}
						if (a.name > b.name) {
							return -1;
						}
						return 0;
					});

					break;
				case 'Model/Asc':
					sorted = data.sort(function (a, b) {
						if (a.model < b.model) {
							return -1;
						}
						if (a.model > b.model) {
							return 1;
						}
						return 0;
					});
					break;
				case 'Model/Desc':
					sorted = data.sort(function (a, b) {
						if (a.model < b.model) {
							return 1;
						}
						if (a.model > b.model) {
							return -1;
						}
						return 0;
					});

					break;

				default:
					sorted = data;
			}
		}
		return sorted;
	};

	return (
		<div className='sessions'>
			<PageHeader
				title='Encoders'
				func={(e) => dispatch(setQuery(e))}
				placeholder='Search with name or code'
			/>
			<div className='sessionsMainContainer'>
				<div
					className='filters'
					style={{
						pointerEvents: Array.isArray(tableData) ? 'all' : 'none',
						opacity: Array.isArray(tableData) ? 1 : 0.6,
					}}>
					<h3>Filters</h3>
					<StatusFilter filterSesions={filterSesions} />
					<Sorting sortSessions={sortSessions} />
				</div>
				<EncodersTable data={tableData} />
			</div>
			<Link to='/encoders/new' className='addSession'></Link>
		</div>
	);
};

export default Encoders;
