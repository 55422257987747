import ReactSwitch from 'react-switch';

const Switch = ({ containerStyle, title, checked, onChange }) => {
	return (
		<div style={{ ...style.container, ...containerStyle }}>
			<p>{title}</p>
			<ReactSwitch onColor='#cc073c' onChange={onChange} checked={checked} />
		</div>
	);
};

const style = {
	container: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginBottom: 10,
	},
};

export default Switch;
