import reactDom from 'react-dom';
import Notification from '../componants/Base/Notification';

const useNotification = () => {
	const mountNote = (type, message, linkName, linkPath) => {
		reactDom.render(
			<Notification
				type={type}
				message={message}
				linkName={linkName}
				linkPath={linkPath}
			/>,
			document.getElementById('note'),
		);
	};
	return { mountNote };
};

export default useNotification;
