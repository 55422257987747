import { useEffect, useState } from 'react';
import Button from '../../componants/Base/Button';
import useFetch from '../../hooks/useFetch';
import useNotification from '../../hooks/useNotification';

import Input from '../../componants/Base/Input';
import moment from 'moment';
import Select from '../../componants/Base/Select';

const NewLeases = () => {
	// hooks and utils
	const { mountNote } = useNotification();
	const { postReq, getData } = useFetch();

	//states
	const [lease, setLease] = useState({
		customer: '',
		description: '',
		encoder: '',
		start: moment().format('YYYY-MM-DDThh:mm'),
		end: moment().add(3, 'days').format('YYYY-MM-DDThh:mm'),
	});
	const [encoders, setEncoders] = useState(null);

	const [loading, setLoading] = useState(false);
	const [selectLoader, setSelectLoader] = useState(false);

	//submit changes

	const submit = async () => {
		setLoading(true);
		const res = await postReq('leases', lease);
		res !== 'error' && mountNote('success', 'Lease Created successfully');

		setLoading(false);
	};

	useEffect(() => {
		const getEncoders = async () => {
			setSelectLoader(true);
			let res = await getData(
				`leases/candidates?start=${moment(lease.start)
					.utc()
					.format()}&end=${moment(lease.end).utc().format()}`,
			);
			if (res !== 'error') {
				setEncoders(res);
				setLease({ ...lease, encoder: res[0]?._id || '' });
			}
			setSelectLoader(false);
		};

		if (lease.start && lease.end) {
			if (moment(lease.start).isAfter(lease.end)) {
				mountNote('error', 'dates conflict , please choose a proper date');
			} else {
				getEncoders();
			}
		} // eslint-disable-next-line
	}, [lease.end, lease.start]);

	return (
		<div style={{ padding: 50 }}>
			<h1>
				<span style={{ color: '#FF0000' }}>New </span> Lease
			</h1>
			<form
				style={{
					display: 'flex',
					padding: '12px 0',
					justifyContent: 'space-between',
				}}>
				<div style={{ width: '49%' }}>
					<Input
						containerStyle={style.inpCont}
						placeholder='Customer ID'
						title='Customer'
						type='text'
						value={lease.customer}
						onChange={(e) => setLease({ ...lease, customer: e })}
					/>
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							marginTop: -10,
						}}>
						<Input
							containerStyle={{ width: '49%' }}
							title='From'
							value={lease.start}
							type='datetime-local'
							onChange={(e) => setLease({ ...lease, start: e })}
						/>
						<Input
							containerStyle={{ width: '49%' }}
							title='To'
							value={lease.end}
							type='datetime-local'
							onChange={(e) => setLease({ ...lease, end: e })}
						/>
					</div>
				</div>
				<div style={{ width: '49%' }}>
					<Input
						containerStyle={style.inpCont}
						placeholder='Description'
						title='Description'
						type='text'
						value={lease.description}
						onChange={(e) => setLease({ ...lease, description: e })}
					/>
					<Select
						title='Encoder'
						loading={selectLoader}
						value={lease.encoder}
						disabled={encoders === null || selectLoader}
						select={(e) => setLease({ ...lease, encoder: e })}>
						{encoders ? (
							encoders.map((encoder) => (
								<option key={encoder._id} value={encoder._id}>
									({encoder.inputs}) {encoder.code}:{encoder.name}
								</option>
							))
						) : (
							<option>Select the dates to get the encoders</option>
						)}
					</Select>
				</div>
			</form>

			<div className='buttons'>
				<Button
					disabled={loading}
					title='CREATE'
					loading={loading}
					filled
					func={() => submit()}
				/>
			</div>
		</div>
	);
};
const style = {
	inpCont: {
		margin: '10px 0',
	},
	buttons: {
		margin: 'auto',
		width: 'fit-content',
	},
};
export default NewLeases;
