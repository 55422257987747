import { useState } from 'react';
import useFetch from '../../hooks/useFetch';

const Rename = ({ id, value, dismiss, updateImages }) => {
	const { postReq } = useFetch();
	const [name, setName] = useState(value);
	const [loading, setLoading] = useState(false);
	const rename = async () => {
		setLoading(true);
		const url = 'cdn/' + id;

		const res = await postReq(url, {
			name,
		});

		updateImages((prev) =>
			prev.map((image) => (image._id === res._id ? res : image)),
		);
		setLoading(false);
		dismiss();
	};
	return (
		<div className='popup'>
			<div className='container'>
				<input
					type='text'
					name='name'
					value={name}
					onChange={(e) => setName(e.target.value)}
				/>
				<div>
					<button disabled={loading} onClick={rename}>
						Submit
						{loading && (
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='15'
								height='15'
								stroke='#fff'
								style={{ marginLeft: 10 }}
								viewBox='0 0 38 38'>
								<g
									fill='none'
									fillRule='evenodd'
									strokeWidth='4'
									transform='translate(1 1)'>
									<circle cx='18' cy='18' r='18' strokeOpacity='0.5'></circle>
									<path d='M36 18c0-9.94-8.06-18-18-18'>
										<animateTransform
											attributeName='transform'
											dur='1s'
											from='0 18 18'
											repeatCount='indefinite'
											to='360 18 18'
											type='rotate'></animateTransform>
									</path>
								</g>
							</svg>
						)}
					</button>
					<button className='cancel' onClick={dismiss}>
						cancel
					</button>
				</div>
			</div>
		</div>
	);
};

export default Rename;
