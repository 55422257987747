import { useDispatch, useSelector } from 'react-redux';
import { setFilters } from '../../Redux/Reducers/LeasesFilters';

const StatusFilter = () => {
	const filters = useSelector((state) => state.LeasesFilters.filters);
	const dispatch = useDispatch();

	return (
		<div className='status'>
			<h4>Status</h4>
			<div className='statusContainer'>
				<label>
					<input
						defaultChecked={filters['Future']}
						onChange={(e) =>
							dispatch(setFilters({ ...filters, Future: e.target.checked }))
						}
						type='checkbox'
					/>
					Future
				</label>
				<label>
					<input
						defaultChecked={filters['Closed']}
						type='checkbox'
						onChange={(e) =>
							dispatch(setFilters({ ...filters, Closed: e.target.checked }))
						}
					/>
					Closed
				</label>
				<label>
					<input
						defaultChecked={filters['Out']}
						type='checkbox'
						onChange={(e) =>
							dispatch(setFilters({ ...filters, Out: e.target.checked }))
						}
					/>
					Out
				</label>
			</div>
		</div>
	);
};

export default StatusFilter;
